import React, { useState } from 'react';
import { useSendLoginMutation } from 'rtk/rtkApi/authApi';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { LoginForm } from 'components/form/custom/loginForm';
import { useSelector } from 'react-redux';
import { CloseSVG, TelegramSVG } from 'resources/svg';
import s from './index.module.scss';

export const ModalLogin = ({ hideModal, showModalCode }) => {
  const clientId = useSelector((state) => state.botInfo.clientId);
  const [sendLogin, { isLoading }] = useSendLoginMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const navigatorLink = useSelector((state) => state.botInfo.navigatorLink);

  const handleSendLogin = async (values) => {
    setErrorMessage('');
    const { login } = values;
    const result = await sendLogin({ username: login, repeat: true, clientId });
    // console.log('handleSendLogin ', result);

    if (result?.data?.message === 'Code sent to telegram') {
      showModalCode(login);
    }

    if (result?.error?.status === 404) {
      setErrorMessage('Такого пользователя не существует');
    }
  };

  return (
    <ModalBackdrop hideModal={hideModal}>
      <div className={s.container__scroll}>
        {/* eslint-disable-next-line */}
        <div className={s.container__flex} onClick={hideModal}>
          {/* eslint-disable-next-line */}
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <div className={s.modal__row}>
              <p className={s.modal__title}>Вход</p>
              <PrimaryBtn customClassName={s.modal__button__close} onClick={hideModal}>
                <CloseSVG />
              </PrimaryBtn>
            </div>
            <LoginForm
              handleSendLogin={handleSendLogin}
              errorMessage={errorMessage}
              isLoading={isLoading}
            />

            <a href={navigatorLink} target="blank" className={s.auth__link}>
              <TelegramSVG color="#263238" />
              <p>зарегистрироваться</p>
            </a>
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};
