import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { useCreateTradeMutation } from 'rtk/rtkApi/tradeApi';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { OrderForm } from 'components/form/custom/orderForm';
import { useModal } from 'hooks/useModal';
import { useSelector } from 'react-redux';
import { getCurrencySign } from 'utils/convert';
import { ModalOrderSuccess } from 'components/modal/modalOrderSuccess';
import { Seller } from './components/seller';
import { Product } from './components/product';
import s from './index.module.scss';

export const TradeContent = ({ data }) => {
  const currencySign = getCurrencySign(data?.currency?.ISO);
  const currentUser = useSelector((state) => state.user.currentUser); // currentUser обязателен
  const [createTrade] = useCreateTradeMutation();
  const [step, setStep] = useState(1);
  const { isMobile } = useWindowDimensions();
  const [totalCost, setTotalCost] = useState(0);
  const { showModal, hideModal } = useModal();
  const navigate = useNavigate();

  // console.log('TradeContent', data);

  const showModalSuccess = () => {
    showModal(ModalOrderSuccess, {
      showModal,
      hideModal
    });
  };

  const seller = data?.user;
  const sellerAvatar = data?.user?.avatar;
  const [products, setProducts] = useState([{ ...data, count: 1, allPrice: data.price }]);

  const changeProducts = (id, newCount, newAllPrice) => {
    setProducts(
      products.map((item) =>
        item.id === id ? { ...item, count: newCount, allPrice: newAllPrice } : item
      )
    );
  };

  const nexStep = (stepNumber) => {
    setStep(stepNumber);
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    const newTotalCost = products.reduce((previous, current) => previous + current.allPrice, 0);
    setTotalCost(newTotalCost);
  }, [products]);

  const sendOrder = async (orderFormValues) => {
    const orderRequestBody = {
      // status: 'CREATED',
      quantity: String(products?.[0]?.count),
      // buyerId: currentUser?.id ? currentUser?.id : 'bcc65db1-feed-4f39-b571-c6e5902b9128',
      sellerId: String(products?.[0]?.user?.id),
      comment: orderFormValues.comment,
      postsId: [products?.[0]?.id],
      name: orderFormValues.userName,
      phone: orderFormValues.phone,
      address: orderFormValues.address
    };

    if (currentUser?.id) {
      orderRequestBody.buyerId = currentUser?.id;
    }
    const result = await createTrade(orderRequestBody);
    if (result?.data) {
      navigate('/');
      showModalSuccess();
    }
  };

  // mobile version
  if (isMobile) {
    return (
      <div className={s.section}>
        {step === 1 && (
          <div className={s.products}>
            <Seller sellerAvatar={sellerAvatar} seller={seller} />
            {products.map((productData) => (
              <Product
                key={productData.id}
                productData={productData}
                changeProducts={changeProducts}
              />
            ))}
            <PrimaryBtn onClick={() => nexStep(2)} customClassName={s.button__forward}>
              Далее
            </PrimaryBtn>
          </div>
        )}

        {step === 2 && (
          <div className={s.order__form}>
            <OrderForm sendOrder={sendOrder} totalCost={totalCost} currencySign={currencySign} />
          </div>
        )}
      </div>
    );
  }

  // desktop version
  return (
    <div className={s.section}>
      <div className={s.products}>
        <Seller sellerAvatar={sellerAvatar} seller={seller} />
        {products.map((productData) => (
          <Product key={productData.id} productData={productData} changeProducts={changeProducts} />
        ))}
        <PrimaryBtn customClassName={s.button__forward}>Далее</PrimaryBtn>
      </div>

      <div className={s.order__form}>
        <OrderForm sendOrder={sendOrder} totalCost={totalCost} currencySign={currencySign} />
      </div>
    </div>
  );
};
