import React, { useRef } from 'react';
import Slider from 'react-slick';
import { useGetSimilarPostsQuery } from 'rtk/rtkApi/similarPostApi';
import { convertToQueryParams } from 'utils/convert';
import { SimilarSmallCard } from 'components/cards/similarSmallCard';
import { SliderArrowPrev, SliderArrowNext } from 'resources/svg';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { useParams } from 'react-router';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import clsx from 'clsx';
import s from './index.module.scss';

export const SimilarProductRow = () => {
  const { id } = useParams();
  const queryString = convertToQueryParams({ quantity: 20 });
  const { data: similarData } = useGetSimilarPostsQuery({ id, queryString });

  const sliderRef = useRef(null);

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };

  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    variableWidth: true
  };

  if (!similarData?.meta?.total || !similarData?.list) {
    return null;
  }

  return (
    <>
      <div className={s.title__wrapper}>
        <h4 className={s.title}>Похожие товары</h4>
      </div>

      <div className={s.slider__wrapper}>
        {similarData?.list.length > 4 && (
          <>
            <PrimaryBtn customClassName={clsx(s.slider__button, s.prev)} onClick={handlePrev}>
              <SliderArrowPrev />
            </PrimaryBtn>
            <PrimaryBtn customClassName={clsx(s.slider__button, s.next)} onClick={handleNext}>
              <SliderArrowNext />
            </PrimaryBtn>
          </>
        )}

        {similarData?.list.length > 4 ? (
          <div className={s.slider}>
            <Slider {...settings} ref={sliderRef}>
              {similarData.list.length > 0 &&
                similarData.list.map((cardData) => (
                  <div className={s.slider__item} key={cardData.id}>
                    <div className={s.slider__item__inner}>
                      <SimilarSmallCard cardData={cardData} />
                    </div>
                  </div>
                ))}
            </Slider>
          </div>
        ) : (
          <div className={s.slider}>
            <div className={s.slider__inner}>
              {similarData.list.length > 0 &&
                similarData.list.map((cardData) => (
                  <div className={s.slider__item} key={cardData.id}>
                    <div className={s.slider__item__inner}>
                      <SimilarSmallCard cardData={cardData} />
                    </div>
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    </>
  );
};
