/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'rtk/constants';

const initialState = {
  cities: '',
  categoriesId: [],
  text: '',
  page: 1,
  sortId: 0,
  minPrice: 0,
  maxPrice: 0,
  typesPostId: '',
  withPhotos: false,
  quantity: 9,
  catalogViewType: 'tile'
};

export const filterSlice = createSlice({
  name: Slices.filter,
  initialState,
  reducers: {
    setCity: (state, action) => {
      state.cities = action.payload;
    },
    setCatalogViewType: (state, action) => {
      state.catalogViewType = action.payload;
    },
    setCategory: (state, action) => {
      state.categoriesId = action.payload;
    },
    setSearch: (state, action) => {
      state.text = action.payload;
    },
    setPage: (state, action) => {
      state.page = action.payload;
    },
    setSortId: (state, action) => {
      state.sortId = action.payload;
    },
    setMinPrice: (state, action) => {
      state.minPrice = action.payload;
    },
    setMaxPrice: (state, action) => {
      state.maxPrice = action.payload;
    },
    setType: (state, action) => {
      state.typesPostId = action.payload;
    },
    setWithPhotos: (state, action) => {
      state.withPhotos = action.payload;
    },
    resetFilters: (state) => {
      state.text = '';
      state.cities = '';
      state.categoriesId = [];
      state.page = 1;
      state.sortId = 0;
      state.minPrice = 0;
      state.maxPrice = 0;
      state.typesPostId = '';
      state.withPhotos = false;
    }
  }
});

export const {
  setCatalogViewType,
  setCity,
  setCategory,
  setSearch,
  setPage,
  setSortId,
  setMinPrice,
  setMaxPrice,
  setType,
  resetFilters,
  setWithPhotos
} = filterSlice.actions;

export default filterSlice.reducer;
