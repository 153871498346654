import React from 'react';
import { components } from 'react-select';
import { SmallCrossSVG } from 'resources/svg';

const { MultiValueRemove } = components;

export const CustomMultiValueRemove = (props) => (
  <MultiValueRemove {...props}>
    <SmallCrossSVG />
  </MultiValueRemove>
);
