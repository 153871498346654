export const Slices = {
  user: 'user',
  filter: 'filter',
  botInfo: 'botInfo'
};

export const AsyncSlices = {
  post: {
    reducerPath: 'postApi',
    id: 'POST',
    type: 'Posts'
  },
  similarPost: {
    reducerPath: 'similarPostApi',
    id: 'SIMILAR_POST',
    type: 'SimilarPosts'
  },
  comment: {
    reducerPath: 'commentApi',
    id: 'COMMENT',
    type: 'Comments'
  },
  sortOptions: {
    reducerPath: 'sortOptionsApi',
    id: 'SORT_OPTIONS',
    type: 'SortOptions'
  },
  cityOptions: {
    reducerPath: 'cityOptionsApi',
    id: 'CITY_OPTIONS',
    type: 'CityOptions'
  },
  auth: {
    reducerPath: 'authApi',
    id: 'AUTH',
    type: 'Auth'
  },
  trade: {
    reducerPath: 'tradeApi',
    id: 'TRADE',
    type: 'Trade'
  },
  user: {
    reducerPath: 'userApi',
    id: 'USER',
    type: 'User'
  }
};
