/* eslint-disable */
import { Header } from 'components/containers/header';
import { Footer } from 'components/containers/footer';
import React from 'react';
import { Helmet } from 'react-helmet';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import s from './index.module.scss';

export const MainLayout = ({ children, breadcrumbs, route }) => {
  const adCodes = useSelector((state) => state.botInfo.adCodes);
  const { isTablet } = useWindowDimensions();

  const footerDesktopAd = adCodes.find(
    (item) => item?.device === 'DESKTOP' && item.location === 'FOOTER'
  );

  const footerMobileAd = adCodes.find(
    (item) => item?.device === 'MOBILE' && item.location === 'FOOTER'
  );

  return (
    <div className={s.page}>
      <Header />
      <Outlet />
      <div className={s.page__content}>{children || null}</div>

      {isTablet && footerDesktopAd && (
        <div className={s.wrapper__footerAdd}>
          <div id={footerDesktopAd.id}></div>
          <Helmet>
            <script>
              {`window.yaContextCb.push(()=>
                {Ya.Context.AdvManager.render({
                renderTo: '${footerDesktopAd.renderTo}',
                blockId: '${footerDesktopAd.blockId}'
              })}
              )`}
            </script>
          </Helmet>
        </div>
      )}

      {!isTablet && footerMobileAd && (
        <div className={s.wrapper__footerAdd}>
          <div id={footerMobileAd.id}></div>
          <Helmet>
            <script>
              {`window.yaContextCb.push(()=>
                {Ya.Context.AdvManager.render({
                renderTo: '${footerMobileAd.renderTo}',
                blockId: '${footerMobileAd.blockId}'
              })}
              )`}
            </script>
          </Helmet>
        </div>
      )}

      <Footer />
    </div>
  );
};
