import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { setCurrentUser } from 'rtk/slices/userSlice';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { Logo } from 'components/logo';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { CloseSVG, TelegramSVG } from 'resources/svg';
import s from './index.module.scss';

export const ModalMenu = ({ hideModal, showModalLogin }) => {
  const [menuVisible, setMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const currentUser = useSelector((state) => state.user.currentUser);
  const botInfoLink = useSelector((state) => state.botInfo.botInfoLink);

  const handleLogout = () => {
    localStorage.removeItem('marketCurrentUser');
    dispatch(setCurrentUser(null));
    hideModal();
  };

  useEffect(() => {
    setMenuVisible(true);
  }, []);

  return (
    <ModalBackdrop hideModal={hideModal}>
      <div className={clsx(s.container__scroll, menuVisible && s.container__scroll__visible)}>
        {/* eslint-disable-next-line */}
        <div className={s.container__flex} onClick={hideModal}>
          {/* eslint-disable-next-line */}
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <div className={s.heading}>
              <Logo />
              <PrimaryBtn customClassName={s.button__close} onClick={hideModal}>
                <CloseSVG />
                <p>закрыть</p>
              </PrimaryBtn>
            </div>

            <Link to="/" className={s.menu__link} onClick={hideModal}>
              Объявления
            </Link>
            <Link to="/aboutProject" className={s.menu__link} onClick={hideModal}>
              О проекте
            </Link>
            <Link to="/aboutDeveloper" className={s.menu__link} onClick={hideModal}>
              О разработчике
            </Link>
            <PrimaryBtn customClassName={s.button__announcement}>
              <TelegramSVG />
              <a href={botInfoLink} target="blank">
                добавить объявление
              </a>
            </PrimaryBtn>
            {/* {currentUser ? (
              <PrimaryBtn customClassName={s.button__auth} onClick={handleLogout}>
                выход
              </PrimaryBtn>
            ) : (
              <PrimaryBtn customClassName={s.button__auth} onClick={showModalLogin}>
                вход
              </PrimaryBtn>
            )} */}
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};
