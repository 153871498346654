import React from 'react';
import { useSendLoginMutation } from 'rtk/rtkApi/authApi';
import { TelegramSVG } from 'resources/svg';
import clsx from 'clsx';
import { FormContainer } from 'components/form/formContainer';
import { FormikNumber } from 'components/form/inputs/number';
import { FormikField } from 'components/form/inputs/input';
import { useSelector } from 'react-redux';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import s from './index.module.scss';

export const CodeForm = ({
  loginName,
  showModalLogin,
  handleSendCode,
  errorMessage,
  setErrorMessage,
  isFirstSendingCode
}) => {
  const [sendLogin] = useSendLoginMutation();
  const navigatorLink = useSelector((state) => state.botInfo.navigatorLink);
  const clientId = useSelector((state) => state.botInfo.clientId);

  const initialValues = {
    login: loginName,
    code: ''
  };

  const onSubmit = (values) => {
    handleSendCode(values);
  };

  const handleSendCodeAgain = (resetForm) => {
    setErrorMessage('');
    sendLogin({ username: loginName, repeat: true, clientId });
    resetForm();
  };

  return (
    <>
      <FormContainer initialValues={initialValues} onSubmit={onSubmit} validateOnChange={false}>
        {(formik) => {
          const { resetForm } = formik;
          return (
            <>
              <FormikField
                customClassName={clsx(s.input, s.input__disabled)}
                type="text"
                name="login"
                disabled
              />

              <button className={s.button__back} type="button" onClick={showModalLogin}>
                Указать другой аккаунт
              </button>

              <div className={s.input__code__wrapper}>
                <FormikNumber
                  customClassName={clsx(s.input, errorMessage && s.input__error)}
                  name="code"
                  placeholder="Код для входа"
                />
              </div>

              {errorMessage && <p className={s.message__error}>{errorMessage}</p>}

              <PrimaryBtn customClassName={s.button__submit} type="submit">
                Войти
              </PrimaryBtn>

              {isFirstSendingCode ? (
                <a href={navigatorLink} target="blank" className={s.auth__link}>
                  <TelegramSVG color="#263238" />
                  <p>зарегистрироваться</p>
                </a>
              ) : (
                <PrimaryBtn
                  customClassName={s.modal__button__more}
                  onClick={() => handleSendCodeAgain(resetForm)}
                >
                  отправить код еще раз
                </PrimaryBtn>
              )}
            </>
          );
        }}
      </FormContainer>
    </>
  );
};
