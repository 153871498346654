import React from 'react';
import YandexShare from 'react-yandex-share';
import { ShareSVG } from 'resources/svg';
import { PrimaryBtn } from '../primaryBtn';
import s from './index.module.scss';

export const YandexShareBtn = ({ image, title, description }) => {
  const yandexSettings = {
    content: {
      title,
      url: window.location.href,
      description,
      image
    },

    theme: {
      lang: 'en',
      services: 'vkontakte,viber,telegram,whatsapp',
      moreButtonType: 'short',
      limit: 0,
      shape: 'round',
      curtain: true,
      bare: false,
      colorScheme: 'blackwhite',
      useLinks: true
    }
  };

  return (
    <PrimaryBtn customClassName={s.button__share}>
      <div className={s.share__svg__wrapper}>
        <ShareSVG />
      </div>
      <YandexShare {...yandexSettings} />
    </PrimaryBtn>
  );
};
