import React from 'react';
import { useDispatch } from 'react-redux';
import { setSortId } from 'rtk/slices/filterSlice';
import { FormContainer } from 'components/form/formContainer';
import { FormikSelect } from 'components/form/inputs/select';
import { useGetSortOptionsQuery } from 'rtk/rtkApi/sortOptionsApi';
import { CustomDropdownIndicator } from '../customDropdownIndicator';
import { SortingCustomStyle } from './sortingCustomStyle';

export const SortingPosts = () => {
  const dispatch = useDispatch();
  const { data: sortOptions = [] } = useGetSortOptionsQuery();

  const initialValues = {
    sortBy: ''
  };

  const onSubmit = (values) => {
    dispatch(setSortId(values.value));
  };

  return (
    <>
      <FormContainer initialValues={initialValues} validateOnChange={false} onSubmit={onSubmit}>
        {() => (
          <>
            <FormikSelect
              customStyles={SortingCustomStyle}
              options={sortOptions}
              components={{
                IndicatorSeparator: () => null,
                DropdownIndicator: CustomDropdownIndicator
              }}
              placeholder="Сортировать по"
              name="sortBy"
              onChange={onSubmit}
              maxMenuHeight={200}
              menuPlacement="auto"
            />
          </>
        )}
      </FormContainer>
    </>
  );
};
