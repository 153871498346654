import React from 'react';
import { useFormikContext, useField } from 'formik';
import InputMask from 'react-input-mask';

export const FormikPhone = (props) => {
  const { name = '', type = 'tel', onChange, customClassName, ...other } = props;
  const [field] = useField(name);
  const form = useFormikContext();

  const handleChange = (e) => {
    form.setFieldValue(name, e.target.value);
    if (onChange) onChange(e);
  };

  const inputValue = field.value || '';

  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    type,
    onChange: handleChange
  };

  return (
    <InputMask
      className={customClassName}
      {...evalProps}
      mask="+7 999 999 99 99"
      alwaysShowMask={false}
    />
  );
};
