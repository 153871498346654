import React from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationArrowNext, PaginationArrowPrev } from 'resources/svg';
import { setPage } from 'rtk/slices/filterSlice';
import s from './index.module.scss';

export const Pagination = ({ totalCards }) => {
  const dispatch = useDispatch();
  const currentPage = useSelector((state) => state.filter.page);
  const quantityPerPage = 8;
  const pageQuantity = Math.ceil(totalCards / quantityPerPage);
  const prevArrowColor = currentPage === 1 ? 'rgba(0, 0, 0, 0.3)' : '#000';
  const nextArrowColor = currentPage === pageQuantity ? 'rgba(0, 0, 0, 0.3)' : '#000';

  const pageChangeHandler = ({ selected }) => {
    dispatch(setPage(selected + 1));
  };

  return (
    <>
      <ReactPaginate
        breakLabel="..."
        nextLabel={<PaginationArrowNext color={nextArrowColor} />}
        previousLabel={<PaginationArrowPrev color={prevArrowColor} />}
        pageCount={pageQuantity}
        onPageChange={pageChangeHandler}
        forcePage={currentPage - 1}
        containerClassName={s.pagination__container}
        activeClassName={s.page__current}
        pageClassName={s.page__item}
        nextClassName={s.page__next}
        previousClassName={s.page__prev}
        breakClassName={s.page__break}
      />
    </>
  );
};
