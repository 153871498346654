export const SortingCustomStyle = {
  control: () => ({
    height: '3.125rem',
    display: 'flex',
    borderRadius: '4px',
    border: '1px solid rgba(0, 0, 0, 0.23)'
  }),
  option: (provided, state) => ({
    ...provided,
    minHeight: '2.25rem',
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.04);' : '#fff',
    // color: '#263238'
    color: '#000000'
  }),

  singleValue: (provided) => ({
    ...provided,
    color: 'rgba(0, 0, 0, 0.87)',
    fontWeight: '600'
  }),

  menuList: (provided) => ({
    ...provided,

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px'
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: '0.5rem'
  })
};
