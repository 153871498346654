/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'constants/data';
import { AsyncSlices } from 'rtk/constants';
import { baseQuery } from './baseApi';

export const { reducerPath, id, type } = AsyncSlices.sortOptions;

export const sortOptionsApi = createApi({
  reducerPath,
  tagTypes: [type],
  baseQuery,

  endpoints: (build) => ({
    getSortOptions: build.query({
      query: () => 'post/sort-options'
    })
  })
});

export const { useGetSortOptionsQuery } = sortOptionsApi;
