export const filterStyles = {
  container: (provided) => ({
    ...provided,
    width: '100%'
  }),

  input: (provided) => ({
    ...provided,
    color: '#263238',
    fontWeight: '600'
  }),

  singleValue: (provided) => ({
    ...provided,
    color: '#263238',
    fontWeight: '600'
  }),

  indicatorsContainer: (provided) => ({
    ...provided,
    paddingRight: '0.7rem'
  }),

  control: (provided) => ({
    ...provided,
    border: 'none',
    boxShadow: 'none',
    padding: '3px',
    backgroundColor: 'transparent'
  }),

  option: (provided, state) => ({
    ...provided,
    minHeight: '2.25rem',
    backgroundColor: state.isFocused ? 'rgba(0, 0, 0, 0.04);' : '#fff',
    // color: '#263238'
    color: '#000'
  }),

  menuList: (provided) => ({
    ...provided,

    '::-webkit-scrollbar': {
      width: '6px',
      height: '0px'
    },
    '::-webkit-scrollbar-track': {
      background: '#f1f1f1'
    },
    '::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '4px'
    },
    '::-webkit-scrollbar-thumb:hover': {
      background: '#555'
    }
  }),
  multiValue: (provided) => ({
    ...provided,
    border: '1px solid rgba(0, 0, 0, 0.26)',
    borderRadius: '100px',
    fontSize: '0.8125rem',
    padding: '3px 2px 3px 0.5rem',
    backgroundColor: 'transparent',
    lineHeight: '1rem'
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: '3rem',
    backgroundColor: 'transparent',
    ':hover': {
      backgroundColor: 'transparent'
    }
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: '#263238',
    letterSpacing: '0.16px',
    fontSize: '13px'
  })
};
