/* eslint-disable */
import React from 'react';
import { Helmet } from 'react-helmet';
import parse from 'html-react-parser';
import { useNavigate } from 'react-router';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { YandexShareBtn } from 'components/buttons/yandexShareBtn';
import { apiUrl } from 'constants/data';
import { useGetPostQuery } from 'rtk/rtkApi/postApi';
import { Loader } from 'components/loader';
import { DetailSlider } from 'components/detailSlider';
import { getCurrencySign } from 'utils/convert';
import { LocationSVG } from 'resources/svg';
import { useSelector } from 'react-redux';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import s from './index.module.scss';

export const DetailContent = ({ id }) => {
  const adCodes = useSelector((state) => state.botInfo.adCodes);
  const { data, isLoading } = useGetPostQuery(id);
  const { isTablet } = useWindowDimensions();
  const navigate = useNavigate();
  const currencySign = getCurrencySign(data?.currency?.ISO);
  const sellerLink = `https://t.me/${data?.user.username}`;

  const handleNavigate = () => {
    navigate(`/trade/${id}`);
  };

  const cardDesktopAd = adCodes.find(
    (item) => item?.device === 'DESKTOP' && item.location === 'CARD'
  );

  const cardMobileAd = adCodes.find(
    (item) => item?.device === 'MOBILE' && item.location === 'CARD'
  );

  if (isLoading) {
    return (
      <div className={s.loader__wrapper}>
        <Loader />
      </div>
    );
  }

  return (
    <div className={s.detailContent__wrapper}>
      <div className={s.wrapper}>
        <p className={s.detail__category}>{data?.category?.title}</p>
        <p className={s.detail__date}>{data?.createAt}</p>
        <h3 className={s.detail__title}>{data?.title}</h3>

        {data?.city && (
          <div className={s.location}>
            <div className={s.location__icon}>
              <LocationSVG />
            </div>
            <p className={s.location__city}>{data?.city}</p>
          </div>
        )}

        {data?.price ? (
          <p className={s.price}>{`${data?.price.toLocaleString()} ${currencySign}`}</p>
        ) : (
          <p className={s.price__empty}>Цена не указана</p>
        )}

        <div className={s.button__group}>
          <PrimaryBtn customClassName={s.button__buy} onClick={handleNavigate}>
            купить
          </PrimaryBtn>
          <a href={sellerLink} target="blank" className={s.button__to__seller}>
            Написать продавцу
          </a>
          <YandexShareBtn
            image={data?.photos[0] && `${apiUrl}${data?.photos[0].path}`}
            title={data?.title}
            description={data?.description}
          />
        </div>
      </div>
      <div className={s.wrapper__slider}>
        <DetailSlider images={data?.photos} />
      </div>

      <div className={s.wrapper}>
        <div className={s.box}>
          {data?.description && (
            <div className={s.desc}>
              <h4 className={s.desc__title}>Описание</h4>
              <p className={s.desc__text}>{parse(data.description.replace(/\n/g, '<br/>'))}</p>
            </div>
          )}

          {isTablet && cardDesktopAd && (
            <div className={s.wrapper__cardAdd}>
              <div id={cardDesktopAd.id}></div>
              <Helmet>
                <script>
                  {`window.yaContextCb.push(()=>
                {Ya.Context.AdvManager.render({
                renderTo: '${cardDesktopAd.renderTo}',
                blockId: '${cardDesktopAd.blockId}'
              })}
              )`}
                </script>
              </Helmet>
            </div>
          )}

          {!isTablet && cardMobileAd && (
            <div className={s.wrapper__cardAdd}>
              <div id={cardMobileAd.id}></div>
              <Helmet>
                <script>
                  {`window.yaContextCb.push(()=>
                {Ya.Context.AdvManager.render({
                renderTo: '${cardMobileAd.renderTo}',
                blockId: '${cardMobileAd.blockId}'
              })}
              )`}
                </script>
              </Helmet>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
