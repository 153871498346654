import React from 'react';
import { useFormikContext, useField } from 'formik';

export const FormikNumber = (props) => {
  const { name = '', type = 'number', onChange, customClassName, ...other } = props;
  const [field] = useField(name);
  const form = useFormikContext();

  const handleChange = (e) => {
    form.setFieldValue(name, e.target.value);
    if (onChange) onChange(e.target.value);
  };

  const inputValue = field.value || '';

  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    type,
    onChange: handleChange
  };

  return (
    <>
      <input {...evalProps} className={customClassName} />
    </>
  );
};
