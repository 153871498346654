/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'constants/data';
import { AsyncSlices } from 'rtk/constants';
import { baseQuery } from './baseApi';

export const { reducerPath, id, type } = AsyncSlices.comment;

export const commentApi = createApi({
  reducerPath,
  tagTypes: [type],
  baseQuery,

  endpoints: (build) => ({
    getComments: build.query({
      query: ({ id, queryString }) => {
        if (queryString) {
          return `comment/for-post/${id}?${queryString}`;
        }
        return `comment/for-post/${id}`;
      }
    })
  })
});

export const { useGetCommentsQuery } = commentApi;
