import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';
import { useDebounce } from 'hooks/useDebonce';

export const FormikAutocomplete = (props) => {
  const {
    name = '',
    placeholder,
    customStyles,
    onChange,
    defaultValue,
    useQueryHook,
    ...other
  } = props;
  const form = useFormikContext();
  const [field] = useField(name);
  const [inputValue, setInputValue] = useState('');
  const [lastOptions, setLastOptions] = useState([]);

  const handleChange = (option) => {
    form.setFieldValue(name, option.value);
    if (onChange) onChange(option);
  };

  const handleOnChange = (newValue) => {
    setInputValue(newValue);
  };

  const evalProps = {
    ...field,
    ...other,
    id: name,
    onChange: handleChange
  };

  const selectedValue = field.value || null;
  const debouncedSearchValue = useDebounce(inputValue, 500);
  const { data } = useQueryHook(debouncedSearchValue);

  useEffect(() => {
    if (data && data.length > 0) {
      const options = data.map((item) => ({ label: item.title, value: item.title }));
      setLastOptions(options);
    }
  }, [data]);

  const getValue = () => {
    if (lastOptions && lastOptions.length > 0 && selectedValue) {
      return lastOptions.find((option) => option.value === selectedValue);
    }
    return defaultValue ? { label: defaultValue, value: defaultValue } : '';
  };

  return (
    <>
      <Select
        name={name}
        id={name}
        styles={customStyles}
        options={lastOptions}
        onChange={handleChange}
        onInputChange={handleOnChange}
        placeholder={placeholder}
        inputValue={inputValue}
        {...evalProps}
        value={getValue()}
        noOptionsMessage={() => null}
        components={{
          IndicatorSeparator: () => null,
          DropdownIndicator: () => null
        }}
      />
    </>
  );
};
