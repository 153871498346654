import React from 'react';
import clsx from 'clsx';
import s from './index.module.scss';

export const ModalBackdrop = ({ children, hideModal, customClassName }) => {
  const hideAllModal = (e) => {
    if (e.target.id === 'ModalBackdrop') hideModal();
  };

  return (
    // eslint-disable-next-line
    <div className={clsx(s.backdrop, customClassName)} onClick={hideAllModal} id="ModalBackdrop">
      {children}
    </div>
  );
};
