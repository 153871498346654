import React from 'react';
import { useSelector } from 'react-redux';
import { apiUrl } from 'constants/data';
import clsx from 'clsx';
import s from './index.module.scss';

export const Logo = ({ customClassName }) => {
  const logoLink = useSelector((state) => state.botInfo.logoLink);
  const communityName = useSelector((state) => state.botInfo.communityName);

  if (!logoLink && !communityName) {
    return null;
  }

  return (
    <div className={clsx(s.logo__wrapper, customClassName && customClassName)}>
      {logoLink ? (
        <img src={`${apiUrl}${logoLink}`} alt="" />
      ) : (
        <h3 className={s.logo__string}>{communityName.slice(0, 33)}</h3>
      )}
    </div>
  );
};
