import React from 'react';
import Select from 'react-select';
import { useField, useFormikContext } from 'formik';

export const FormikSelect = (props) => {
  const {
    name = '',
    isMulti = false,
    options,
    placeholder,
    customStyles,
    onChange,
    ...other
  } = props;
  const form = useFormikContext();
  const [field] = useField(name);

  const handleChange = (option) => {
    form.setFieldValue(name, isMulti ? option.map((item) => item.value) : option.value);

    if (onChange) onChange(option);
  };

  const getValue = () => {
    if (options) {
      return isMulti
        ? options.filter((option) => field.value.indexOf(option.value) >= 0)
        : options.find((option) => option.value === field.value);
    }
    return isMulti ? [] : '';
  };

  const evalProps = {
    ...field,
    ...other,
    value: getValue(),
    id: name,
    onChange: handleChange
  };

  return (
    <>
      <Select
        name={name}
        styles={customStyles}
        options={options}
        isMulti={isMulti}
        value={getValue()}
        onChange={handleChange}
        placeholder={placeholder}
        {...evalProps}
      />
    </>
  );
};
