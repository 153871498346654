/* eslint-disable react/jsx-no-bind */
import React from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';

import { useModal } from 'hooks/useModal';
import { Logo } from 'components/logo';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { ModalMenu } from 'components/modal/modalMenu';
import { ModalLogin } from 'components/modal/modalLogin';
import { ModalCode } from 'components/modal/modalCode';
import { ModalSuccess } from 'components/modal/modalSuccess';
import { Dropdown } from 'components/dropdown';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentUser } from 'rtk/slices/userSlice';
import { TelegramSVG, BurgerSVG, TriangleSVG, EnterSVG, AddSVG } from 'resources/svg';
import s from './index.module.scss';

export const Header = ({ shadow }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const botInfoLink = useSelector((state) => state.botInfo.botInfoLink);
  const adsChannelLink = useSelector((state) => state.botInfo.adsChannelLink);
  const dispatch = useDispatch();
  const { showModal, hideModal } = useModal();

  const showModalSuccess = () => {
    showModal(ModalSuccess, { showModal, hideModal, modalText: 'Успешно' });
  };

  const showModalCode = (loginName) => {
    // eslint-disable-next-line
    showModal(ModalCode, { showModal, hideModal, showModalLogin, showModalSuccess, loginName });
  };

  function showModalLogin() {
    showModal(ModalLogin, { showModal, hideModal, showModalCode });
  }

  const showModalMenu = () => {
    showModal(ModalMenu, { showModal, hideModal, showModalLogin });
  };

  const handleLogout = () => {
    localStorage.removeItem('marketCurrentUser');
    dispatch(setCurrentUser(null));
    // Cookies.remove('refreshToken');
    hideModal();
  };

  return (
    <div className={clsx(s.header, shadow && s.header__shadow)}>
      <div className={s.wrapper}>
        <div className={s.header__content}>
          <div className={s.header__content__left}>
            <Link to={'/'}>
              <Logo customClassName={s.header__logo} />
            </Link>

            <div className={s.navbar}>
              <Link className={s.navbar__link} to={'/'}>
                Объявления
              </Link>
              <Link className={s.navbar__link} to={'/aboutProject'}>
                О проекте
              </Link>
              <Link className={s.navbar__link} to={'/aboutDeveloper'}>
                О разработчике
              </Link>
            </div>
          </div>
          <div className={s.button__group}>
            {currentUser ? (
              <>
                <PrimaryBtn customClassName={s.button__channel}>
                  <TelegramSVG />
                  <a href={adsChannelLink} target="blank">
                    канал объявлений
                  </a>
                </PrimaryBtn>
                <PrimaryBtn customClassName={s.button__add}>
                  <AddSVG />
                  <a href={botInfoLink} target="blank">
                    создать
                  </a>
                </PrimaryBtn>

                {/* <Dropdown
                  toggle={
                    <PrimaryBtn customClassName={s.button__dropdown}>
                      <p>{`@${currentUser?.username}`}</p>
                      <TriangleSVG />
                    </PrimaryBtn>
                  }
                >
                  <PrimaryBtn customClassName={s.button__logout} onClick={handleLogout}>
                    <p>выйти</p>
                  </PrimaryBtn>
                </Dropdown> */}
              </>
            ) : (
              <>
                <PrimaryBtn customClassName={s.button__channel}>
                  <TelegramSVG />
                  <a href={adsChannelLink} target="blank">
                    канал объявлений
                  </a>
                </PrimaryBtn>
                <PrimaryBtn customClassName={s.button__add}>
                  <AddSVG />
                  <a href={botInfoLink} target="blank">
                    создать
                  </a>
                </PrimaryBtn>
                {/* <PrimaryBtn customClassName={s.button__auth} onClick={showModalLogin}>
                  <EnterSVG />
                  <p>вход</p>
                </PrimaryBtn> */}
              </>
            )}
          </div>
          <PrimaryBtn customClassName={s.button__burger} onClick={showModalMenu}>
            <BurgerSVG />
          </PrimaryBtn>
        </div>
      </div>
    </div>
  );
};
