import React from 'react';
import { components } from 'react-select';
import { TriangleSelectSVG } from 'resources/svg';

const { DropdownIndicator } = components;

export const CustomDropdownIndicator = (props) => (
  <DropdownIndicator {...props}>
    <TriangleSelectSVG />
  </DropdownIndicator>
);
