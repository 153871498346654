import React, { useEffect } from 'react';
import { Loader } from 'components/loader';
import { TradeContent } from 'components/containers/detailTrade';
import { useParams } from 'react-router';
import { useGetPostQuery } from 'rtk/rtkApi/postApi';
import { setMetaTags } from 'utils/setMetaTags';
import { useSelector } from 'react-redux';

import s from './index.module.scss';

export const Trade = () => {
  const { id } = useParams();
  const communityName = useSelector((state) => state.botInfo.communityName);
  const { data, isLoading } = useGetPostQuery(id);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setMetaTags(
      `Оформление заказа. ${data?.title}. ${communityName}. Vitrina bot`,
      `Оформление заказа. ${data?.title}. ${communityName}. Vitrina bot. Продукт для создания локального маркетплейса внутри тематических сообществ на базе telegram`
    );
  }, []);

  if (isLoading) {
    return (
      <div className={s.loader__wrapper}>
        <Loader />
      </div>
    );
  }

  if (!data) {
    return <div className={s.wrapper}>Что-то пошло не так</div>;
  }

  return (
    <div className={s.wrapper}>
      <h1 className={s.trade__title}>Оформление заказа</h1>

      <TradeContent data={data} />
    </div>
  );
};
