import React from 'react';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { CloseSVG, SuccessSVG } from 'resources/svg';
import s from './index.module.scss';

export const ModalSuccess = ({ hideModal, modalText }) => (
  <ModalBackdrop hideModal={hideModal}>
    <div className={s.container__scroll}>
      {/* eslint-disable-next-line */}
      <div className={s.container__flex} onClick={hideModal}>
        {/* eslint-disable-next-line */}
        <div className={s.content} onClick={(e) => e.stopPropagation()}>
          <div className={s.modal__row}>
            <PrimaryBtn customClassName={s.modal__button__close} onClick={hideModal}>
              <CloseSVG />
            </PrimaryBtn>
          </div>
          <div className={s.box}>
            <SuccessSVG />
            <p className={s.box__text}>{modalText}</p>
          </div>
        </div>
      </div>
    </div>
  </ModalBackdrop>
);
