/* eslint-disable react/self-closing-comp */
/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { useState } from 'react';
import { apiUrl } from 'constants/data';
import noPhoto from 'resources/images/noPhoto.png';
import { YandexShareBtn } from 'components/buttons/yandexShareBtn';
import clsx from 'clsx';
import s from './index.module.scss';

export const HoverSwiper = ({ photos, title }) => {
  const [currentImage, setCurrentImage] = useState(0);

  const handleSetCurrentImage = (index) => {
    setCurrentImage(index);
  };

  return (
    <div className={s.image__box}>
      <div className={s.social__share}>
        <YandexShareBtn image={photos[0] && `${apiUrl}${photos[0].path}`} title={title} />
      </div>

      <div className={s.swiper}>
        {photos.map((item, index) => (
          <div
            key={item.id}
            className={s.swiper__item}
            onMouseOver={() => handleSetCurrentImage(index)}
          ></div>
        ))}
      </div>

      <div className={s.dot__list}>
        {photos.length > 1 &&
          photos.map((item, index) => (
            <div key={item.id} className={clsx(s.dot, index === currentImage && s.active)}></div>
          ))}
      </div>
      <div className={s.image}>
        {photos[currentImage]?.path ? (
          <img src={`${apiUrl}${photos[currentImage]?.path}`} alt="preview" />
        ) : (
          <img src={noPhoto} alt="preview" />
        )}
      </div>
    </div>
  );
};
