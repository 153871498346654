import React from 'react';
import { getCurrencySign } from 'utils/convert';
import { YandexShareBtn } from 'components/buttons/yandexShareBtn';
import { useNavigate } from 'react-router';
import { LocationSVG, ArrowRightSVG } from 'resources/svg';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import noPhoto from 'resources/images/noPhoto.png';
import { apiUrl } from 'constants/data';
import s from './index.module.scss';

export const SimilarSmallCard = ({ cardData }) => {
  const { id, title, city, price, photos, currency } = cardData;
  const currencySign = getCurrencySign(currency);
  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate(`/good/${id}`);
  };

  return (
    <div className={s.container}>
      <div className={s.image}>
        <div className={s.social__share}>
          <YandexShareBtn image={photos[0] && `${apiUrl}${photos[0].path}`} title={title} />
        </div>

        {photos[0]?.path ? (
          <img src={`${apiUrl}${photos[0]?.path}`} alt="preview" />
        ) : (
          <img src={noPhoto} alt="preview" />
        )}
      </div>

      <div className={s.location}>
        {city && (
          <>
            <div className={s.location__icon}>
              <LocationSVG />
            </div>
            <p className={s.location__city}>{city}</p>
          </>
        )}
      </div>

      {/* eslint-disable-next-line */}
      <h3 className={s.card__title} onClick={handleNavigate}>
        {title}
      </h3>

      <div className={s.card__footer}>
        <div className={s.price}>
          {price ? `${price.toLocaleString()} ${currencySign}` : 'Цена не указана'}
        </div>

        <PrimaryBtn customClassName={s.button__more} onClick={handleNavigate}>
          <p>подробнее</p>
          <ArrowRightSVG />
        </PrimaryBtn>
      </div>
    </div>
  );
};
