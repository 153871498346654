/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import {
  setCity,
  setCategory,
  setSearch,
  resetFilters,
  setPage,
  setMinPrice,
  setMaxPrice,
  setType,
  setWithPhotos
} from 'rtk/slices/filterSlice';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { FormContainer } from 'components/form/formContainer';
import { FormikField } from 'components/form/inputs/input';
import { RubleSVG, SearchSVG } from 'resources/svg';
import { FormikNumber } from 'components/form/inputs/number';
import { FormikCheckbox } from 'components/form/inputs/checkbox';
import { FormikAutocomplete } from 'components/form/inputs/autocomplete';
import { FormikSelect } from 'components/form/inputs/select';
import { useGetCityOptionsQuery } from 'rtk/rtkApi/cityOptionsApi';
import { CustomDropdownIndicator } from '../customDropdownIndicator';
import { CustomMultiValueRemove } from '../customMultiValueRemove';
import { filterStyles } from './filterStyle';
import { SortingCustomStyle } from '../sortingPosts/sortingCustomStyle';
import s from './index.module.scss';

export const Filter = ({ labelClassname, hideModal }) => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filter);
  const botInfoCategories = useSelector((state) => state.botInfo.botInfoCategories);
  const botInfoTypePost = useSelector((state) => state.botInfo.botInfoTypePost);

  let optionsCategory = [];
  let optionsTypePost = [];

  if (botInfoCategories) {
    optionsCategory = botInfoCategories.map((el) => ({
      value: el.id,
      label: el.title,
      id: el.id
    }));
  }

  if (botInfoTypePost) {
    optionsTypePost = botInfoTypePost.map((el) => ({
      value: el.id,
      label: el.title,
      id: el.id
    }));
  }

  const initialValues = {
    search: filters.text,
    city: filters.cities,
    category: filters.categoriesId,
    minPrice: filters.minPrice,
    maxPrice: filters.maxPrice,
    typeAd: filters.typesPostId,
    withPhotos: filters.withPhotos
  };

  const onSubmit = (values) => {
    dispatch(setCity(values.city));
    dispatch(setSearch(values.search));
    dispatch(setCategory([...values.category]));
    dispatch(setPage(1));
    dispatch(setMinPrice(values.minPrice));
    dispatch(setMaxPrice(values.maxPrice));
    dispatch(setType(values.typeAd));
    dispatch(setWithPhotos(values.withPhotos));

    if (hideModal) {
      hideModal();
    }
  };

  const onReset = (resetForm) => {
    dispatch(resetFilters());
    resetForm();
    if (hideModal) {
      hideModal();
    }
  };

  return (
    <>
      <FormContainer initialValues={initialValues} onSubmit={onSubmit} validateOnChange={false}>
        {(formik) => {
          const { resetForm } = formik;
          return (
            <>
              <h3 className={s.title}>Фильтры</h3>

              <div className={clsx(s.input__wrapper, s.input__wrapper__type)}>
                <label
                  className={clsx(s.input__label, labelClassname && labelClassname)}
                  htmlFor="typeAd"
                >
                  Тип
                </label>
                <FormikSelect
                  customStyles={filterStyles}
                  options={optionsTypePost}
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: CustomDropdownIndicator
                  }}
                  placeholder="Тип объявления"
                  name="typeAd"
                  maxMenuHeight={200}
                  menuPlacement="auto"
                />
              </div>

              <div className={s.group}>
                <div className={s.price__box}>
                  <div className={s.input__wrapper__price}>
                    <label
                      className={clsx(s.input__label, labelClassname && labelClassname)}
                      htmlFor="priceMin"
                    >
                      От
                    </label>
                    <div className={s.input__icon}>
                      <RubleSVG />
                    </div>
                    <FormikNumber
                      customClassName={s.input}
                      name="minPrice"
                      placeholder="0"
                      min="0"
                    />
                  </div>
                  <div className={s.input__wrapper__price}>
                    <label
                      className={clsx(s.input__label, labelClassname && labelClassname)}
                      htmlFor="maxPrice"
                    >
                      До
                    </label>
                    <div className={s.input__icon}>
                      <RubleSVG />
                    </div>
                    <FormikNumber
                      customClassName={s.input}
                      name="priceMax"
                      placeholder="0"
                      min="0"
                      max="999999"
                    />
                  </div>
                </div>
              </div>

              <div className={clsx(s.input__wrapper, s.input__wrapper__city)}>
                <label
                  className={clsx(s.input__label, labelClassname && labelClassname)}
                  htmlFor="city"
                >
                  Город
                </label>
                <FormikAutocomplete
                  isClearable={false}
                  name="city"
                  placeholder="Санкт-Петербург"
                  maxMenuHeight={200}
                  menuPlacement="auto"
                  useQueryHook={useGetCityOptionsQuery}
                  customStyles={filterStyles}
                  defaultValue={filters.cities}
                />
              </div>

              <div className={clsx(s.input__wrapper, s.input__wrapper__select)}>
                <label
                  className={clsx(s.input__label, labelClassname && labelClassname)}
                  htmlFor="category"
                >
                  Категория
                </label>
                <FormikSelect
                  components={{
                    IndicatorSeparator: () => null,
                    DropdownIndicator: CustomDropdownIndicator,
                    MultiValueRemove: CustomMultiValueRemove
                  }}
                  isClearable={false}
                  customStyles={filterStyles}
                  options={optionsCategory}
                  name="category"
                  placeholder="Выберите категорию"
                  isMulti
                  maxMenuHeight={200}
                  menuPlacement="auto"
                />
              </div>

              <div className={s.input__wrapper}>
                <label
                  className={clsx(s.input__label, labelClassname && labelClassname)}
                  htmlFor="search"
                >
                  Поиск
                </label>
                <div className={s.input__icon}>
                  <SearchSVG />
                </div>
                <FormikField
                  customClassName={s.input}
                  type="text"
                  name="search"
                  placeholder="Что вы ищете?"
                />
              </div>

              <div className={s.input__wrapper__checkbox}>
                <FormikCheckbox name="withPhotos" />
                <label className={s.checkbox__label} htmlFor="withPhotos">
                  Только с фото
                </label>
              </div>

              <PrimaryBtn type="submit" customClassName={s.button__search}>
                найти
              </PrimaryBtn>
              <PrimaryBtn
                customClassName={s.button__reset}
                onClick={() => {
                  onReset(resetForm);
                }}
              >
                сбросить
              </PrimaryBtn>
            </>
          );
        }}
      </FormContainer>
    </>
  );
};
