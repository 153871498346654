// export const apiUrl = 'https://api.chatbot-dev.asap-lp.ru/api/'; // DEV
export const apiUrl = 'https://api.vitrinabot.online/api/'; // PROD

// export const clientId = 'd9fffe0b-4cbb-4cc0-bd7a-49b93afdf993'; // pavel17

export const breadcrumbsMain = [{ id: 1, title: 'Каталог' }];
export const breadcrumbsDetail = [
  { id: 1, title: 'Каталог' },
  { id: 2, title: 'Объявления' }
];
export const breadcrumbsTrade = [{ id: 1, title: 'Оформление заказа' }];
export const breadcrumbsAboutProject = [{ id: 1, title: 'О проекте' }];
export const breadcrumbsAboutDeveloper = [{ id: 1, title: 'О разработчике' }];
