/* eslint-disable react/jsx-no-bind */
import React from 'react';
import clsx from 'clsx';
import { Logo } from 'components/logo';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { AddSVG, EnterSVG, TelegramSVG, TG2SVG } from 'resources/svg';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { useModal } from 'hooks/useModal';
import { ModalCode } from 'components/modal/modalCode';
import { setCurrentUser } from 'rtk/slices/userSlice';
import { ModalSuccess } from 'components/modal/modalSuccess';
import { ModalLogin } from 'components/modal/modalLogin';
import s from './index.module.scss';

export const Footer = ({ shadow }) => {
  const currentUser = useSelector((state) => state.user.currentUser);
  const botInfoLink = useSelector((state) => state.botInfo.botInfoLink);
  const adsChannelLink = useSelector((state) => state.botInfo.adsChannelLink);
  const dispatch = useDispatch();
  const { showModal, hideModal } = useModal();

  const showModalSuccess = () => {
    showModal(ModalSuccess, { showModal, hideModal, modalText: 'Успешно' });
  };

  const showModalCode = (loginName) => {
    // eslint-disable-next-line
    showModal(ModalCode, { showModal, hideModal, showModalLogin, showModalSuccess, loginName });
  };

  function showModalLogin() {
    showModal(ModalLogin, { showModal, hideModal, showModalCode });
  }

  const handleLogout = () => {
    localStorage.removeItem('marketCurrentUser');
    dispatch(setCurrentUser(null));
    hideModal();
  };

  return (
    <div className={clsx(s.footer, shadow && s.footer__shadow)}>
      <div className={s.wrapper}>
        <div className={s.footer__content}>
          <div className={s.footer__top}>
            <div className={s.footer__logo}>
              <Link to={'/'}>
                <Logo />
              </Link>
            </div>

            <div className={s.button__group}>
              <PrimaryBtn customClassName={s.button__channel}>
                <TelegramSVG />
                <a href={adsChannelLink} target="blank">
                  канал объявлений
                </a>
              </PrimaryBtn>

              <PrimaryBtn customClassName={s.button__add}>
                <AddSVG />
                <a href={botInfoLink} target="blank">
                  создать
                </a>
              </PrimaryBtn>

              {/* {currentUser ? (
                <PrimaryBtn customClassName={s.button__auth} onClick={handleLogout}>
                  выйти
                </PrimaryBtn>
              ) : (
                <PrimaryBtn customClassName={s.button__auth} onClick={showModalLogin}>
                  <EnterSVG />
                  <p>вход</p>
                </PrimaryBtn>
              )} */}
            </div>
          </div>
          <div className={s.footer__row}>
            <div className={s.nav__links__right}>
              <Link to="/" className={s.nav__link}>
                Объявления
              </Link>
              <Link to="/aboutProject" className={s.nav__link}>
                О проекте
              </Link>
              <Link to="/aboutDeveloper" className={s.nav__link}>
                О разработчике
              </Link>
            </div>

            <div className={s.schedule}>
              <p className={s.schedule__text}>Нужна помощь?</p>
              <p className={s.schedule__text}>Мы на связи в телеграм</p>
              <div className={s.social}>
                <a
                  className={s.social__link}
                  href="https://t.me/techsupport_vitrina"
                  target="blank"
                >
                  <div className={s.bottom__link__svg}>
                    <TG2SVG />
                  </div>
                  <p>@techsupport_vitrina</p>
                </a>
              </div>
            </div>
          </div>
          <div className={s.footer__row__bottom}>
            <p className={s.copyright}>© 2022</p>
          </div>
        </div>
      </div>
    </div>
  );
};
