import React, { useEffect } from 'react';
import { TelegramSVG } from 'resources/svg';
import { setMetaTags } from 'utils/setMetaTags';
import s from './index.module.scss';

export const AboutDeveloper = () => {
  useEffect(() => {
    setMetaTags(
      'О разработчике. Vitrina bot',
      'Информация разработчике Vitrina bot. Продукт для создания локального маркетплейса внутри тематических сообществ на базе telegram'
    );
  }, []);

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <h1 className={s.title}>О разработчике</h1>

        <h3 className={s.sub__title}>Вас приветствует команда разработки продукта Vitrina bot.</h3>

        <p className={s.desc}>
          Это инструмент для создания локальных досок объявлений/маркетплейсов внутри отраслевых
          сообществ на базе каналов и ботов в Telegram.
        </p>

        <p className={s.desc__important}>
          Предоставьте участникам вашего сообщества удобную доску объявлений и зарабатывайте на
          этом!
        </p>

        <ul className={s.list}>
          <li className={s.list__item}>
            Участники смогут пользоваться полным функционалом маркетплейса, не покидая привычного
            интерфейса telegram
          </li>
          <li className={s.list__item}>
            На базе создаваемых пользователями объявлений автоматически создается web-сайт, который
            является дополнительной площадкой продаж
          </li>
          <li className={s.list__item}>
            Администратор сообщества получает инструмент контроля и монетизации объявлений и заказов
            участников
          </li>
        </ul>

        <div className={s.functional}>
          <h3 className={s.sub__title}>
            Продукт представляет 4 интерфейса со следующим функционалом:
          </h3>
          <p className={s.desc__important}>1. Telegram-бот и канал для работы с объявлениями</p>
          <p>Полный функционал онлайн доски-объявлений в привычном интерфейсе мессенджера:</p>

          <ul className={s.functional__list}>
            <li className={s.list__item}>Создание объявлений участниками сообщества</li>
            <li className={s.list__item}>
              Публикация объявлений в telegram-канал, комментирование объявлений
            </li>
            <li className={s.list__item}>Поиск и фильтрация по каталогу объявлений</li>
            <li className={s.list__item}>Заказ товара/услуги по объявлению</li>
            <li className={s.list__item}>Платный подъём объявлений</li>
          </ul>
        </div>

        <div>
          <p className={s.desc__important}>2. Web-каталог объявлений</p>
          <p>
            Онлайн-каталог в формате web-сайта на базе объявлений созданных в telegram-канале.
            Является дополнительным каналом продаж и расширяет аудиторию сообщества.
          </p>

          <ul className={s.web__list}>
            <li className={s.list__item}>Авторизация и регистрация через Telegram</li>
            <li className={s.list__item}>Каталог объявлений с фильтрацией и сортировкой</li>
            <li className={s.list__item}>Детальная карточка объявления</li>
            <li className={s.list__item}>Формирование заказа на товар или услугу по объявлению</li>
          </ul>
        </div>

        <div className={s.navbot__box}>
          <p className={s.desc__important}>3. Навигационный бот сообщества</p>
          <p>
            Удобная навигация по всем чатам, каналам и внешним ресурсам для участников сообщества.
          </p>
        </div>

        <div className={s.admin__box}>
          <p className={s.desc__important}>4. Административная панель</p>
          <p>Управление базой пользователей, объявлений, заказов. Настройки продукта.</p>
        </div>

        <div className={s.slogan__box}>
          <p className={s.desc__important}>
            Подписывайтесь на наш канал Vitrina bot, в публикациях мы делимся дальнейшими планами по
            развитию продукта, анонсируем внедрения, даём советы по монетизации сообществ.
          </p>

          <a href="https://t.me/BotDeveloperss" target="blank" className={s.button__subscribe}>
            <p>канал-разработчика</p>
            <TelegramSVG />
          </a>
        </div>
      </div>
    </div>
  );
};
