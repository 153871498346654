import React from 'react';
import { useSelector } from 'react-redux';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { AttentionSVG } from 'resources/svg';
import { Card } from '../card';
import { SmallCard } from '../smallCard';
import s from './index.module.scss';

export const MainCardList = ({ cardList = [] }) => {
  const { isMobile } = useWindowDimensions();
  const catalogViewType = useSelector((state) => state.filter.catalogViewType);

  if (cardList?.length === 0) {
    return (
      <div className={s.cards__no_result}>
        <AttentionSVG />
      </div>
    );
  }

  return (
    <>
      {catalogViewType === 'column' || isMobile ? (
        <div className={s.card__list__column}>
          {cardList.map((card) => (
            <div className={s.card__wrapper} key={card.id}>
              <Card cardData={card} />
            </div>
          ))}
        </div>
      ) : (
        <div className={s.card__list__tile}>
          {cardList.map((card) => (
            <div className={s.small__card__wrapper} key={card.id}>
              <SmallCard cardData={card} />
            </div>
          ))}
        </div>
      )}
    </>
  );
};
