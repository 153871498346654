import React, { useState } from 'react';
import { useSendCodeMutation } from 'rtk/rtkApi/authApi';
import { setCurrentUser } from 'rtk/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { CodeForm } from 'components/form/custom/codeForm';
import { CloseSVG } from 'resources/svg';
import s from './index.module.scss';

export const ModalCode = ({ hideModal, loginName, showModalLogin, showModalSuccess }) => {
  const clientId = useSelector((state) => state.botInfo.clientId);
  const dispatch = useDispatch();
  const [sendCode] = useSendCodeMutation();
  const [errorMessage, setErrorMessage] = useState('');
  const [isFirstSendingCode, setIsFirstSendingCode] = useState(true);

  const handleSendCode = async (values) => {
    setIsFirstSendingCode(false);
    const code = Number(values.code);
    setErrorMessage('');
    const result = await sendCode({ username: values.login, code, clientId });
    // console.log('handleSendCode ', result);

    if (result?.data) {
      dispatch(setCurrentUser(result?.data));
      localStorage.setItem('marketCurrentUser', JSON.stringify(result?.data));
      showModalSuccess();
    }

    if (result?.error?.status === 409) {
      setErrorMessage('Неверный код');
    }
  };

  return (
    <ModalBackdrop hideModal={hideModal}>
      <div className={s.container__scroll}>
        {/* eslint-disable-next-line */}
        <div className={s.container__flex} onClick={hideModal}>
          {/* eslint-disable-next-line */}
          <div className={s.content} onClick={(e) => e.stopPropagation()}>
            <div className={s.modal__row}>
              <p className={s.modal__title}>Вход</p>
              <PrimaryBtn customClassName={s.modal__button__close} onClick={hideModal}>
                <CloseSVG />
              </PrimaryBtn>
            </div>
            <CodeForm
              loginName={loginName}
              showModalLogin={showModalLogin}
              handleSendCode={handleSendCode}
              errorMessage={errorMessage}
              setErrorMessage={setErrorMessage}
              isFirstSendingCode={isFirstSendingCode}
            />
          </div>
        </div>
      </div>
    </ModalBackdrop>
  );
};
