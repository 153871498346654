/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'constants/data';
import { AsyncSlices } from 'rtk/constants';
import { baseQuery } from './baseApi';

export const { reducerPath, id, type } = AsyncSlices.similarPost;

export const similarPostApi = createApi({
  reducerPath,
  tagTypes: [type],
  baseQuery,

  endpoints: (build) => ({
    getSimilarPosts: build.query({
      query: ({ id, queryString }) => `post/similar/${id}?${queryString && queryString}`
    })
  })
});

export const { useGetSimilarPostsQuery } = similarPostApi;
