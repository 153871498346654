import React from 'react';

export const CartSVG = () => (
  <svg width="18" height="21" viewBox="0 0 21 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 11.375V6.875C15 4.38972 12.9853 2.375 10.5 2.375C8.01472 2.375 6 4.38972 6 6.875V11.375M2.625 9.125H18.375L19.5 22.625H1.5L2.625 9.125Z"
      stroke="#1CC700"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TwitterSVG = () => (
  <svg width="20" height="16" viewBox="0 0 22 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M21.1618 2.65593C20.3984 2.99362 19.5888 3.2154 18.7598 3.31393C19.6336 2.79136 20.2875 1.96894 20.5998 0.999927C19.7798 1.48793 18.8808 1.82993 17.9438 2.01493C17.3144 1.34151 16.4802 0.89489 15.5708 0.744511C14.6614 0.594133 13.7277 0.748418 12.9151 1.18338C12.1024 1.61834 11.4562 2.30961 11.0769 3.14972C10.6976 3.98983 10.6066 4.93171 10.8178 5.82893C9.15492 5.74558 7.52814 5.31345 6.0431 4.56059C4.55805 3.80773 3.24794 2.75097 2.19781 1.45893C1.8261 2.09738 1.63076 2.82315 1.63181 3.56193C1.63181 5.01193 2.36981 6.29293 3.49181 7.04293C2.82782 7.02202 2.17844 6.84271 1.59781 6.51993V6.57193C1.59801 7.53763 1.93218 8.47354 2.54366 9.22099C3.15513 9.96843 4.00629 10.4814 4.95281 10.6729C4.33642 10.84 3.69012 10.8646 3.06281 10.7449C3.32968 11.5762 3.84982 12.3031 4.5504 12.824C5.25099 13.3449 6.09694 13.6337 6.96981 13.6499C6.10229 14.3313 5.10899 14.8349 4.04669 15.1321C2.98439 15.4293 1.87394 15.5142 0.778809 15.3819C2.69051 16.6114 4.9159 17.264 7.18881 17.2619C14.8818 17.2619 19.0888 10.8889 19.0888 5.36193C19.0888 5.18193 19.0838 4.99993 19.0758 4.82193C19.8947 4.23009 20.6014 3.49695 21.1628 2.65693L21.1618 2.65593Z"
      fill="#1A1A1A"
      fillOpacity="0.5"
    />
  </svg>
);

export const FacebookSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.477 0 0 4.477 0 10C0 14.991 3.657 19.128 8.438 19.879V12.89H5.898V10H8.438V7.797C8.438 5.291 9.93 3.907 12.215 3.907C13.309 3.907 14.453 4.102 14.453 4.102V6.562H13.193C11.95 6.562 11.563 7.333 11.563 8.124V10H14.336L13.893 12.89H11.563V19.879C16.343 19.129 20 14.99 20 10C20 4.477 15.523 0 10 0Z"
      fill="#1A1A1A"
      fillOpacity="0.5"
    />
  </svg>
);
export const InstagramSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C12.717 0 13.056 0.00999994 14.122 0.0599999C15.187 0.11 15.912 0.277 16.55 0.525C17.21 0.779 17.766 1.123 18.322 1.678C18.8305 2.1779 19.224 2.78259 19.475 3.45C19.722 4.087 19.89 4.813 19.94 5.878C19.987 6.944 20 7.283 20 10C20 12.717 19.99 13.056 19.94 14.122C19.89 15.187 19.722 15.912 19.475 16.55C19.2247 17.2178 18.8311 17.8226 18.322 18.322C17.822 18.8303 17.2173 19.2238 16.55 19.475C15.913 19.722 15.187 19.89 14.122 19.94C13.056 19.987 12.717 20 10 20C7.283 20 6.944 19.99 5.878 19.94C4.813 19.89 4.088 19.722 3.45 19.475C2.78233 19.2245 2.17753 18.8309 1.678 18.322C1.16941 17.8222 0.775931 17.2175 0.525 16.55C0.277 15.913 0.11 15.187 0.0599999 14.122C0.0129999 13.056 0 12.717 0 10C0 7.283 0.00999994 6.944 0.0599999 5.878C0.11 4.812 0.277 4.088 0.525 3.45C0.775236 2.78218 1.1688 2.17732 1.678 1.678C2.17767 1.16923 2.78243 0.775729 3.45 0.525C4.088 0.277 4.812 0.11 5.878 0.0599999C6.944 0.0129999 7.283 0 10 0ZM10 5C8.67392 5 7.40215 5.52678 6.46447 6.46447C5.52678 7.40215 5 8.67392 5 10C5 11.3261 5.52678 12.5979 6.46447 13.5355C7.40215 14.4732 8.67392 15 10 15C11.3261 15 12.5979 14.4732 13.5355 13.5355C14.4732 12.5979 15 11.3261 15 10C15 8.67392 14.4732 7.40215 13.5355 6.46447C12.5979 5.52678 11.3261 5 10 5ZM16.5 4.75C16.5 4.41848 16.3683 4.10054 16.1339 3.86612C15.8995 3.6317 15.5815 3.5 15.25 3.5C14.9185 3.5 14.6005 3.6317 14.3661 3.86612C14.1317 4.10054 14 4.41848 14 4.75C14 5.08152 14.1317 5.39946 14.3661 5.63388C14.6005 5.8683 14.9185 6 15.25 6C15.5815 6 15.8995 5.8683 16.1339 5.63388C16.3683 5.39946 16.5 5.08152 16.5 4.75ZM10 7C10.7956 7 11.5587 7.31607 12.1213 7.87868C12.6839 8.44129 13 9.20435 13 10C13 10.7956 12.6839 11.5587 12.1213 12.1213C11.5587 12.6839 10.7956 13 10 13C9.20435 13 8.44129 12.6839 7.87868 12.1213C7.31607 11.5587 7 10.7956 7 10C7 9.20435 7.31607 8.44129 7.87868 7.87868C8.44129 7.31607 9.20435 7 10 7Z"
      fill="#1A1A1A"
      fillOpacity="0.5"
    />
  </svg>
);

export const AppleSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g>
      <path
        d="M21.792 18.7038C21.429 19.5423 20.9994 20.3141 20.5016 21.0237C19.8231 21.9911 19.2676 22.6607 18.8395 23.0325C18.1758 23.6429 17.4647 23.9555 16.7032 23.9733C16.1566 23.9733 15.4973 23.8177 14.73 23.5022C13.9601 23.1881 13.2525 23.0325 12.6056 23.0325C11.9271 23.0325 11.1994 23.1881 10.4211 23.5022C9.64153 23.8177 9.01355 23.9821 8.53342 23.9984C7.80322 24.0295 7.07539 23.7081 6.3489 23.0325C5.88521 22.6281 5.30523 21.9348 4.61043 20.9526C3.86498 19.9037 3.25211 18.6875 2.77198 17.3009C2.25777 15.8031 2 14.3528 2 12.9487C2 11.3403 2.34754 9.95308 3.04367 8.7906C3.59076 7.85685 4.31859 7.12028 5.22953 6.57955C6.14046 6.03883 7.12473 5.76328 8.18469 5.74565C8.76467 5.74565 9.52524 5.92505 10.4704 6.27764C11.4129 6.6314 12.0181 6.8108 12.2834 6.8108C12.4817 6.8108 13.154 6.60103 14.2937 6.18282C15.3714 5.79498 16.281 5.6344 17.0262 5.69765C19.0454 5.86061 20.5624 6.65659 21.5712 8.09062C19.7654 9.18481 18.8721 10.7174 18.8898 12.6834C18.9061 14.2147 19.4617 15.4891 20.5535 16.5009C21.0483 16.9705 21.6009 17.3335 22.2156 17.5912C22.0823 17.9779 21.9416 18.3482 21.792 18.7038ZM17.161 0.480625C17.161 1.6809 16.7225 2.80159 15.8484 3.83889C14.7937 5.07204 13.5179 5.78461 12.1343 5.67217C12.1167 5.52818 12.1065 5.37663 12.1065 5.21737C12.1065 4.06511 12.6081 2.83196 13.4989 1.8237C13.9436 1.31319 14.5092 0.888716 15.1951 0.550104C15.8796 0.216543 16.5269 0.0320773 17.1358 0.000488281C17.1536 0.160946 17.161 0.321414 17.161 0.48061V0.480625Z"
        fill="#1A1A1A"
        fillOpacity="0.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_46_40678">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const GithubSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 0C5.3724 0 0 5.3808 0 12.0204C0 17.3304 3.438 21.8364 8.2068 23.4252C8.8068 23.5356 9.0252 23.1648 9.0252 22.8456C9.0252 22.5612 9.0156 21.804 9.0096 20.802C5.6712 21.528 4.9668 19.1904 4.9668 19.1904C4.422 17.8008 3.6348 17.4312 3.6348 17.4312C2.5452 16.6872 3.7176 16.7016 3.7176 16.7016C4.9212 16.7856 5.5548 17.94 5.5548 17.94C6.6252 19.776 8.364 19.2456 9.0468 18.9384C9.1572 18.162 9.4668 17.6328 9.81 17.3328C7.146 17.0292 4.344 15.9972 4.344 11.3916C4.344 10.08 4.812 9.006 5.5788 8.166C5.4552 7.8624 5.0436 6.6396 5.6964 4.986C5.6964 4.986 6.7044 4.662 8.9964 6.2172C9.97532 5.95022 10.9853 5.81423 12 5.8128C13.02 5.8176 14.046 5.9508 15.0048 6.2172C17.2956 4.662 18.3012 4.9848 18.3012 4.9848C18.9564 6.6396 18.5436 7.8624 18.4212 8.166C19.1892 9.006 19.6548 10.08 19.6548 11.3916C19.6548 16.0092 16.848 17.0256 14.1756 17.3232C14.6064 17.694 14.9892 18.4272 14.9892 19.5492C14.9892 21.1548 14.9748 22.452 14.9748 22.8456C14.9748 23.1672 15.1908 23.5416 15.8004 23.424C18.19 22.6225 20.2672 21.0904 21.7386 19.0441C23.2099 16.9977 24.001 14.5408 24 12.0204C24 5.3808 18.6264 0 12 0Z"
      fill="#1A1A1A"
      fillOpacity="0.5"
    />
  </svg>
);

export const TelegramSVG = ({ color = 'white' }) => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.37605 7.80808C7.66005 5.04358 15.7921 1.67308 16.9146 1.20658C19.8591 -0.0144154 20.7626 0.219584 20.3121 2.92358C19.9886 4.86708 19.0551 11.3016 18.3111 15.3066C17.8696 17.6816 16.8791 17.9631 15.3216 16.9356C14.5726 16.4411 10.7921 13.9411 9.97155 13.3541C9.22255 12.8191 8.18955 12.1756 9.48505 10.9081C9.94605 10.4566 12.9681 7.57108 15.3226 5.32508C15.6311 5.03008 15.2436 4.54558 14.8876 4.78208C11.7141 6.88658 7.31405 9.80758 6.75405 10.1881C5.90805 10.7626 5.09555 11.0261 3.63705 10.6071C2.53505 10.2906 1.45855 9.91308 1.03955 9.76908C-0.573947 9.21508 -0.190947 8.49758 1.37605 7.80808Z"
      fill={color}
    />
  </svg>
);

export const TG2SVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.43 8.85893C11.2628 9.3444 8.93014 10.3492 5.43189 11.8733C4.86383 12.0992 4.56626 12.3202 4.53917 12.5363C4.49339 12.9015 4.95071 13.0453 5.57347 13.2411C5.65818 13.2678 5.74595 13.2954 5.83594 13.3246C6.44864 13.5238 7.27283 13.7568 7.70129 13.766C8.08994 13.7744 8.52373 13.6142 9.00264 13.2853C12.2712 11.079 13.9584 9.96381 14.0643 9.93977C14.139 9.92281 14.2426 9.90148 14.3128 9.96385C14.3829 10.0262 14.376 10.1443 14.3686 10.176C14.3233 10.3691 12.5281 12.0381 11.5991 12.9018C11.3095 13.171 11.1041 13.362 11.0621 13.4056C10.968 13.5033 10.8721 13.5958 10.78 13.6846C10.2108 14.2333 9.78391 14.6448 10.8036 15.3168C11.2936 15.6397 11.6858 15.9067 12.0769 16.1731C12.5042 16.4641 12.9303 16.7543 13.4816 17.1157C13.6221 17.2077 13.7562 17.3034 13.8869 17.3965C14.3841 17.751 14.8307 18.0694 15.3826 18.0186C15.7032 17.9891 16.0345 17.6876 16.2027 16.7884C16.6002 14.6631 17.3816 10.0585 17.5622 8.16097C17.578 7.99473 17.5581 7.78197 17.5422 7.68857C17.5262 7.59518 17.4928 7.46211 17.3714 7.3636C17.2276 7.24694 17.0056 7.22234 16.9064 7.22408C16.455 7.23203 15.7626 7.47282 12.43 8.85893Z"
      fill="#22BDFF"
    />
  </svg>
);

export const BurgerSVG = () => (
  <svg width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 12H18V10H0V12ZM0 7H18V5H0V7ZM0 0V2H18V0H0Z" fill="#263238" />
  </svg>
);

export const LocationSVG = () => (
  <svg
    width="0.625rem"
    height="0.9rem"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.00016 7.06673C4.55814 7.06673 4.13421 6.89114 3.82165 6.57858C3.50909 6.26602 3.3335 5.84209 3.3335 5.40006C3.3335 4.95804 3.50909 4.53411 3.82165 4.22155C4.13421 3.90899 4.55814 3.7334 5.00016 3.7334C5.44219 3.7334 5.86611 3.90899 6.17867 4.22155C6.49123 4.53411 6.66683 4.95804 6.66683 5.40006C6.66683 5.61893 6.62372 5.83566 6.53996 6.03787C6.4562 6.24008 6.33344 6.42381 6.17867 6.57858C6.02391 6.73334 5.84018 6.85611 5.63797 6.93986C5.43576 7.02362 5.21903 7.06673 5.00016 7.06673ZM5.00016 0.733398C3.76249 0.733398 2.5755 1.22506 1.70033 2.10023C0.825161 2.9754 0.333496 4.16239 0.333496 5.40006C0.333496 8.90006 5.00016 14.0667 5.00016 14.0667C5.00016 14.0667 9.66683 8.90006 9.66683 5.40006C9.66683 4.16239 9.17516 2.9754 8.29999 2.10023C7.42482 1.22506 6.23784 0.733398 5.00016 0.733398Z"
      fill="black"
      fillOpacity="0.38"
    />
  </svg>
);

export const ArrowRightSVG = ({ color = 'white' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6 0L4.9425 1.0575L9.1275 5.25H0V6.75H9.1275L4.9425 10.9425L6 12L12 6L6 0Z"
      fill={color}
    />
  </svg>
);

export const ShareSVG = () => (
  <svg width="18" height="20" viewBox="0 0 18 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15 14.08C14.24 14.08 13.56 14.38 13.04 14.85L5.91 10.7C5.96 10.47 6 10.24 6 10C6 9.76 5.96 9.53 5.91 9.3L12.96 5.19C13.5 5.69 14.21 6 15 6C16.66 6 18 4.66 18 3C18 1.34 16.66 0 15 0C13.34 0 12 1.34 12 3C12 3.24 12.04 3.47 12.09 3.7L5.04 7.81C4.5 7.31 3.79 7 3 7C1.34 7 0 8.34 0 10C0 11.66 1.34 13 3 13C3.79 13 4.5 12.69 5.04 12.19L12.16 16.35C12.11 16.56 12.08 16.78 12.08 17C12.08 18.61 13.39 19.92 15 19.92C16.61 19.92 17.92 18.61 17.92 17C17.92 15.39 16.61 14.08 15 14.08Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export const SearchSVG = () => (
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12.7549 11.2549H11.9649L11.6849 10.9849C12.6649 9.84488 13.2549 8.36488 13.2549 6.75488C13.2549 3.16488 10.3449 0.254883 6.75488 0.254883C3.16488 0.254883 0.254883 3.16488 0.254883 6.75488C0.254883 10.3449 3.16488 13.2549 6.75488 13.2549C8.36488 13.2549 9.84488 12.6649 10.9849 11.6849L11.2549 11.9649V12.7549L16.2549 17.7449L17.7449 16.2549L12.7549 11.2549ZM6.75488 11.2549C4.26488 11.2549 2.25488 9.24488 2.25488 6.75488C2.25488 4.26488 4.26488 2.25488 6.75488 2.25488C9.24488 2.25488 11.2549 4.26488 11.2549 6.75488C11.2549 9.24488 9.24488 11.2549 6.75488 11.2549Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export const TriangleSelectSVG = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="black" fillOpacity="0.54" />
  </svg>
);

export const SmallCrossSVG = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10 0C4.47 0 0 4.47 0 10C0 15.53 4.47 20 10 20C15.53 20 20 15.53 20 10C20 4.47 15.53 0 10 0ZM15 13.59L13.59 15L10 11.41L6.41 15L5 13.59L8.59 10L5 6.41L6.41 5L10 8.59L13.59 5L15 6.41L11.41 10L15 13.59Z"
      fill="black"
      fillOpacity="0.26"
    />
  </svg>
);

export const SliderArrowPrev = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0L9.41 1.41L3.83 7H16V9H3.83L9.41 14.59L8 16L0 8L8 0Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export const SliderArrowNext = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export const FilterSVG = ({ color = '#1CC700' }) => (
  <svg width="15" height="10" viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M5.83333 10H9.16667V8.33333H5.83333V10ZM0 0V1.66667H15V0H0ZM2.5 5.83333H12.5V4.16667H2.5V5.83333Z"
      fill={color}
    />
  </svg>
);

export const CloseSVG = ({ color = '#263238' }) => (
  <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M11.8332 1.34175L10.6582 0.166748L5.99984 4.82508L1.3415 0.166748L0.166504 1.34175L4.82484 6.00008L0.166504 10.6584L1.3415 11.8334L5.99984 7.17508L10.6582 11.8334L11.8332 10.6584L7.17484 6.00008L11.8332 1.34175Z"
      fill={color}
    />
  </svg>
);

export const PaginationArrowPrev = ({ color = 'rgba(0, 0, 0, 0.87)' }) => (
  <svg width="7" height="10" viewBox="0 0 7 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.84175 1.175L5.66675 0L0.666748 5L5.66675 10L6.84175 8.825L3.02508 5L6.84175 1.175Z"
      fill={color}
      fillOpacity="0.87"
    />
  </svg>
);

export const PaginationArrowNext = ({ color = 'rgba(0, 0, 0, 0.87)' }) => (
  <svg width="8" height="10" viewBox="0 0 8 10" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M2.0876 0L0.912598 1.175L4.72926 5L0.912598 8.825L2.0876 10L7.0876 5L2.0876 0Z"
      fill={color}
      fillOpacity="0.87"
    />
  </svg>
);

export const AttentionSVG = () => (
  <svg width="49" height="49" viewBox="0 0 49 49" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M24.5 14.6667V24.5M24.5 34.3333H24.5246M46.625 24.5C46.625 36.7193 36.7193 46.625 24.5 46.625C12.2807 46.625 2.375 36.7193 2.375 24.5C2.375 12.2807 12.2807 2.375 24.5 2.375C36.7193 2.375 46.625 12.2807 46.625 24.5Z"
      stroke="rgba(0, 0, 0, 0.44)"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TGSVG = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0C18.6274 0 24 5.37258 24 12ZM12.43 8.85893C11.2628 9.3444 8.93014 10.3492 5.43189 11.8733C4.86383 12.0992 4.56626 12.3202 4.53917 12.5363C4.49339 12.9015 4.95071 13.0453 5.57347 13.2411C5.65818 13.2678 5.74595 13.2954 5.83594 13.3246C6.44864 13.5238 7.27283 13.7568 7.70129 13.766C8.08994 13.7744 8.52373 13.6142 9.00264 13.2853C12.2712 11.079 13.9584 9.96381 14.0643 9.93977C14.139 9.92281 14.2426 9.90148 14.3128 9.96385C14.3829 10.0262 14.376 10.1443 14.3686 10.176C14.3233 10.3691 12.5281 12.0381 11.5991 12.9018C11.3095 13.171 11.1041 13.362 11.0621 13.4056C10.968 13.5033 10.8721 13.5958 10.78 13.6846C10.2108 14.2333 9.78391 14.6448 10.8036 15.3168C11.2936 15.6397 11.6858 15.9067 12.0769 16.1731C12.5042 16.4641 12.9303 16.7543 13.4816 17.1157C13.6221 17.2077 13.7562 17.3034 13.8869 17.3965C14.3841 17.751 14.8307 18.0694 15.3826 18.0186C15.7032 17.9891 16.0345 17.6876 16.2027 16.7884C16.6002 14.6631 17.3816 10.0585 17.5622 8.16097C17.578 7.99473 17.5581 7.78197 17.5422 7.68857C17.5262 7.59518 17.4928 7.46211 17.3714 7.3636C17.2276 7.24694 17.0056 7.22234 16.9064 7.22408C16.455 7.23203 15.7626 7.47282 12.43 8.85893Z"
      fill="#8C8C8C"
    />
  </svg>
);

export const RubleSVG = () => (
  <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M6.0625 7.53906H0.242188V6.3125H6.0625C6.63021 6.3125 7.09635 6.22135 7.46094 6.03906C7.82552 5.85677 8.09375 5.59896 8.26562 5.26562C8.44271 4.93229 8.53125 4.53906 8.53125 4.08594C8.53125 3.71615 8.44792 3.36198 8.28125 3.02344C8.11979 2.6849 7.85677 2.40625 7.49219 2.1875C7.13281 1.96875 6.65625 1.85938 6.0625 1.85938H3.35938V12H1.85938V0.625H6.0625C6.92188 0.625 7.64583 0.773438 8.23438 1.07031C8.82292 1.36198 9.26823 1.76823 9.57031 2.28906C9.8776 2.80469 10.0312 3.39844 10.0312 4.07031C10.0312 4.79948 9.8776 5.42448 9.57031 5.94531C9.26823 6.46094 8.82292 6.85677 8.23438 7.13281C7.64583 7.40365 6.92188 7.53906 6.0625 7.53906ZM5.96875 8.60938V9.84375H0.242188V8.60938H5.96875Z"
      fill="black"
      fillOpacity="0.54"
    />
  </svg>
);

export const CheckSVG = () => (
  <svg
    width="0.9rem"
    height="0.7rem"
    viewBox="0 0 14 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 6L5 11L14 2L12.5901 0.580078L5 8.16992L1.40991 4.59009L0 6Z"
      fill="#E0E0E0"
    />
  </svg>
);
export const CheckArrowNextSVG = () => (
  <svg
    width="0.5rem"
    height="0.8rem"
    viewBox="0 0 7 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.2925 0.5L0 1.7925L4.19833 6L0 10.2075L1.2925 11.5L6.7925 6L1.2925 0.5Z"
      fill="black"
      fillOpacity="0.28"
    />
  </svg>
);

export const SuccessSVG = () => (
  <svg width="60" height="59" viewBox="0 0 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M51.75 29.125C51.75 41.3443 41.8443 51.25 29.625 51.25C17.4057 51.25 7.5 41.3443 7.5 29.125C7.5 16.9057 17.4057 7 29.625 7C41.8443 7 51.75 16.9057 51.75 29.125Z"
      stroke="#1CC700"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M19.5 31L26 37.5L41.5 22"
      stroke="#1CC700"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const TriangleSVG = () => (
  <svg width="10" height="6" viewBox="0 0 10 6" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M0 0.5L5 5.5L10 0.5H0Z" fill="black" fillOpacity="0.54" />
  </svg>
);

export const EnterSVG = ({ color = '#263238' }) => (
  <svg width="21" height="18" viewBox="0 0 21 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M15.4214 4L14.0114 5.41L16.5914 8H6.42139V10H16.5914L14.0114 12.58L15.4214 14L20.4214 9L15.4214 4ZM2.42139 2H10.4214V0H2.42139C1.32139 0 0.421387 0.9 0.421387 2V16C0.421387 17.1 1.32139 18 2.42139 18H10.4214V16H2.42139V2Z"
      fill={color}
    />
  </svg>
);

export const AddSVG = ({ color = 'white' }) => (
  <svg width="17" height="20" viewBox="0 0 17 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4214 0H2.42139C1.32139 0 0.431387 0.9 0.431387 2L0.421387 18C0.421387 19.1 1.31139 20 2.41139 20H14.4214C15.5214 20 16.4214 19.1 16.4214 18V6L10.4214 0ZM12.4214 14H9.42139V17H7.42139V14H4.42139V12H7.42139V9H9.42139V12H12.4214V14ZM9.42139 7V1.5L14.9214 7H9.42139Z"
      fill={color}
    />
  </svg>
);

export const ListSVG = ({ color = '#00000066' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 7C3 6.448 3.448 6 4 6C4.552 6 5 6.448 5 7C5 7.552 4.552 8 4 8C3.448 8 3 7.552 3 7ZM3 12C3 11.448 3.448 11 4 11C4.552 11 5 11.448 5 12C5 12.552 4.552 13 4 13C3.448 13 3 12.552 3 12ZM4 16C3.448 16 3 16.448 3 17C3 17.552 3.448 18 4 18C4.552 18 5 17.552 5 17C5 16.448 4.552 16 4 16ZM7.9355 11H20.0645C20.5795 11 21.0005 11.421 21.0005 11.936V12.064C21.0005 12.579 20.5795 13 20.0645 13H7.9355C7.4205 13 7.0005 12.579 7.0005 12.064V11.936C7.0005 11.421 7.4205 11 7.9355 11ZM20.0645 16H7.9355C7.4205 16 7.0005 16.421 7.0005 16.936V17.064C7.0005 17.579 7.4205 18 7.9355 18H20.0645C20.5795 18 21.0005 17.579 21.0005 17.064V16.936C21.0005 16.421 20.5795 16 20.0645 16ZM7.9355 6H20.0645C20.5795 6 21.0005 6.421 21.0005 6.936V7.064C21.0005 7.579 20.5795 8 20.0645 8H7.9355C7.4205 8 7.0005 7.579 7.0005 7.064V6.936C7.0005 6.421 7.4205 6 7.9355 6Z"
      fill={color}
    />
    <mask id="mask0_1784_38196" maskUnits="userSpaceOnUse" x="3" y="6" width="19" height="12">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3 7C3 6.448 3.448 6 4 6C4.552 6 5 6.448 5 7C5 7.552 4.552 8 4 8C3.448 8 3 7.552 3 7ZM3 12C3 11.448 3.448 11 4 11C4.552 11 5 11.448 5 12C5 12.552 4.552 13 4 13C3.448 13 3 12.552 3 12ZM4 16C3.448 16 3 16.448 3 17C3 17.552 3.448 18 4 18C4.552 18 5 17.552 5 17C5 16.448 4.552 16 4 16ZM7.9355 11H20.0645C20.5795 11 21.0005 11.421 21.0005 11.936V12.064C21.0005 12.579 20.5795 13 20.0645 13H7.9355C7.4205 13 7.0005 12.579 7.0005 12.064V11.936C7.0005 11.421 7.4205 11 7.9355 11ZM20.0645 16H7.9355C7.4205 16 7.0005 16.421 7.0005 16.936V17.064C7.0005 17.579 7.4205 18 7.9355 18H20.0645C20.5795 18 21.0005 17.579 21.0005 17.064V16.936C21.0005 16.421 20.5795 16 20.0645 16ZM7.9355 6H20.0645C20.5795 6 21.0005 6.421 21.0005 6.936V7.064C21.0005 7.579 20.5795 8 20.0645 8H7.9355C7.4205 8 7.0005 7.579 7.0005 7.064V6.936C7.0005 6.421 7.4205 6 7.9355 6Z"
        fill="white"
      />
    </mask>
    {/* eslint-disable-next-line */}
    <g mask="url(#mask0_1784_38196)"></g>
  </svg>
);

export const GridSVG = ({ color = '#00000066' }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 19V15H19L19.001 19H15ZM19 13H15C13.897 13 13 13.897 13 15V19C13 20.103 13.897 21 15 21H19C20.103 21 21 20.103 21 19V15C21 13.897 20.103 13 19 13ZM5 19V15H9L9.001 19H5ZM9 13H5C3.897 13 3 13.897 3 15V19C3 20.103 3.897 21 5 21H9C10.103 21 11 20.103 11 19V15C11 13.897 10.103 13 9 13ZM15 9V5H19L19.001 9H15ZM19 3H15C13.897 3 13 3.897 13 5V9C13 10.103 13.897 11 15 11H19C20.103 11 21 10.103 21 9V5C21 3.897 20.103 3 19 3ZM5 9V5H9L9.001 9H5ZM9 3H5C3.897 3 3 3.897 3 5V9C3 10.103 3.897 11 5 11H9C10.103 11 11 10.103 11 9V5C11 3.897 10.103 3 9 3Z"
      fill={color}
    />
    <mask id="mask0_1784_38197" maskUnits="userSpaceOnUse" x="3" y="3" width="18" height="18">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15 19V15H19L19.001 19H15ZM19 13H15C13.897 13 13 13.897 13 15V19C13 20.103 13.897 21 15 21H19C20.103 21 21 20.103 21 19V15C21 13.897 20.103 13 19 13ZM5 19V15H9L9.001 19H5ZM9 13H5C3.897 13 3 13.897 3 15V19C3 20.103 3.897 21 5 21H9C10.103 21 11 20.103 11 19V15C11 13.897 10.103 13 9 13ZM15 9V5H19L19.001 9H15ZM19 3H15C13.897 3 13 3.897 13 5V9C13 10.103 13.897 11 15 11H19C20.103 11 21 10.103 21 9V5C21 3.897 20.103 3 19 3ZM5 9V5H9L9.001 9H5ZM9 3H5C3.897 3 3 3.897 3 5V9C3 10.103 3.897 11 5 11H9C10.103 11 11 10.103 11 9V5C11 3.897 10.103 3 9 3Z"
        fill="white"
      />
    </mask>
    {/* eslint-disable-next-line */}
    <g mask="url(#mask0_1784_38197)"></g>
  </svg>
);
