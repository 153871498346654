import { createApi } from '@reduxjs/toolkit/dist/query';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'constants/data';
import Cookies from 'js-cookie';
import { setCurrentUser } from 'rtk/slices/userSlice';

export const baseQuery = fetchBaseQuery({
  baseUrl: apiUrl,
  credentials: 'include',
  prepareHeaders: (headers) => {
    headers.set('Content-Type', 'application/json');
    headers.set('Access-Control-Allow-Credentials', true);
    headers.set(
      'Access-Control-Allow-Origin',
      `${window.location.protocol}//${window.location.host}`
    );
    return headers;
  }
});

// обработка 401/403
// export const baseQueryReauth = async (args, api, extraOptions) => {
//   const result = await baseQuery(args, api, extraOptions);

//   // console.log('result!!!', result);
//   const error = result?.error;

//   if ( error?.data && error?.data?.statusCode === 401) {
//     api.dispatch(setCurrentUser(null));
//   }

//   return result;
// };
