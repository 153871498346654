import React from 'react';
import clsx from 'clsx';
import { FormContainer } from 'components/form/formContainer';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { FormikField } from 'components/form/inputs/input';
import s from './index.module.scss';

export const LoginForm = ({ handleSendLogin, errorMessage, isLoading }) => {
  const initialValues = {
    login: ''
  };

  const onSubmit = (values) => {
    handleSendLogin(values);
  };

  return (
    <>
      <FormContainer initialValues={initialValues} onSubmit={onSubmit} validateOnChange={false}>
        {() => (
          <>
            <FormikField
              customClassName={clsx(s.input, errorMessage && s.input__error)}
              type="text"
              name="login"
              placeholder=" Ваш телеграм"
            />
            {errorMessage && <p className={s.message__error}>{errorMessage}</p>}

            <PrimaryBtn customClassName={s.button__submit} type="submit" disabled={isLoading}>
              войти
            </PrimaryBtn>
          </>
        )}
      </FormContainer>
    </>
  );
};
