/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'rtk/constants';

const initialState = {
  botInfoToken: '',
  botInfoCategories: [],
  botInfoTypePost: [],
  botInfoLink: '',
  navigatorLink: '/',
  adsChannelLink: '/',
  logoLink: '',
  clientId: '',
  mainDesc: '',
  miniDesc: '',
  communityName: '',
  adCodes: []
};

export const botInfoSlice = createSlice({
  name: Slices.botInfo,
  initialState,
  reducers: {
    setBotInfoToken: (state, action) => {
      state.botInfoToken = action.payload;
    },
    setBotInfoCategories: (state, action) => {
      state.botInfoCategories = action.payload;
    },
    setBotInfoTypePost: (state, action) => {
      state.botInfoTypePost = action.payload;
    },
    setBotInfoLink: (state, action) => {
      state.botInfoLink = action.payload;
    },
    setNavigatorLink: (state, action) => {
      state.navigatorLink = action.payload;
    },
    setAdsChannelLink: (state, action) => {
      state.adsChannelLink = action.payload;
    },
    setLogoLink: (state, action) => {
      state.logoLink = action.payload;
    },
    setClientId: (state, action) => {
      state.clientId = action.payload;
    },
    setMainDesc: (state, action) => {
      state.mainDesc = action.payload;
    },
    setMiniDesc: (state, action) => {
      state.miniDesc = action.payload;
    },
    setCommunityName: (state, action) => {
      state.communityName = action.payload;
    },
    setAdCodes: (state, action) => {
      state.adCodes = action.payload;
    }
  }
});

export const {
  setBotInfoToken,
  setBotInfoCategories,
  setBotInfoTypePost,
  setBotInfoLink,
  setNavigatorLink,
  setAdsChannelLink,
  setLogoLink,
  setClientId,
  setMainDesc,
  setMiniDesc,
  setCommunityName,
  setAdCodes
} = botInfoSlice.actions;

export default botInfoSlice.reducer;
