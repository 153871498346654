import { PriceCounter } from 'components/form/custom/priceCounter';
import { apiUrl } from 'constants/data';
import React from 'react';
import noPhoto from 'resources/images/noPhoto.png';
import s from './index.module.scss';

export const Product = ({ productData, changeProducts }) => {
  const productPhoto = productData?.photos?.[0]?.path;
  const { category, city } = productData;

  return (
    <div className={s.product__wrapper}>
      <div className={s.info}>
        <div className={s.info__left}>
          <img src={productPhoto ? `${apiUrl}${productPhoto}` : noPhoto} alt="product" />
        </div>

        <div className={s.info__right}>
          <h3 className={s.title}>{productData?.title}</h3>
          <PriceCounter productData={productData} changeProducts={changeProducts} />
        </div>
      </div>

      <div className={s.tag__list}>
        {category?.title && <p className={s.tag}>{category.title}</p>}
        {city && <p className={s.tag}>{city}</p>}
      </div>
    </div>
  );
};
