/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'constants/data';
import { AsyncSlices } from 'rtk/constants';
import { baseQuery } from './baseApi';

export const { reducerPath, id, type } = AsyncSlices.user;

export const userApi = createApi({
  reducerPath,
  tagTypes: [type],
  baseQuery,

  endpoints: (build) => ({
    getUser: build.query({
      query: (id) => `user/${id}`
    })
  })
});

export const { useGetUserQuery } = userApi;
