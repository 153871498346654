export function convertToQueryParams(filterFilds) {
  const objWithoutEmpty = {};

  Object.keys(filterFilds).forEach((key) => {
    if (typeof filterFilds[key] === 'string' && filterFilds[key]) {
      objWithoutEmpty[key] = filterFilds[key];
    }

    if (typeof filterFilds[key] === 'boolean' || typeof filterFilds[key] === 'number') {
      objWithoutEmpty[key] = filterFilds[key];
    }

    if (Array.isArray(filterFilds[key]) && filterFilds[key].length > 0) {
      objWithoutEmpty[key] = filterFilds[key].join(',');
    }
  });

  const queryString = Object.keys(objWithoutEmpty)
    .map((key) => `${key}=${objWithoutEmpty[key]}`)
    .join('&');
  return queryString;
}

export function convertDate(serverDate) {
  const months = new Map([
    ['01', 'января'],
    ['02', 'февраля'],
    ['03', 'марта'],
    ['04', 'апреля'],
    ['05', 'мая'],
    ['06', 'июня'],
    ['07', 'июля'],
    ['08', 'августа'],
    ['09', 'сентября'],
    ['10', 'октября'],
    ['11', 'ноября'],
    ['12', 'декабря']
  ]);

  const dateStr = serverDate.slice(0, 10);
  const dateArr = dateStr.split('-');
  const year = dateArr[0];
  const month = months.get(dateArr[1]);
  const day = dateArr[2];

  if (dateStr === new Date().toISOString().slice(0, 10)) {
    return 'Сегодня';
  }
  return `${day}\u00A0${month}\u00A0${year}`;
}

export function convertTGLink(str = '') {
  let cleanStr = '';

  if (str[0] === '@') {
    cleanStr = str.slice(1);
  } else {
    cleanStr = str;
  }

  return `https://t.me/${cleanStr}`;
}

export function getCurrencySign(currency) {
  if (!currency) {
    return '';
  }
  const signs = new Map([
    ['RUB', '₽'],
    ['EUR', '€'],
    ['USD', '$'],
    ['GEL', '₾']
  ]);

  return signs.get(currency);
}
