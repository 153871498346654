/* eslint-disable no-shadow */
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrl } from 'constants/data';
import { AsyncSlices } from 'rtk/constants';
import { baseQuery } from './baseApi';

export const { reducerPath, id, type } = AsyncSlices.cityOptions;

export const cityOptionsApi = createApi({
  reducerPath,
  tagTypes: [type],
  baseQuery,

  endpoints: (build) => ({
    getCityOptions: build.query({
      query: (cityStr = '') => `city?${cityStr && `filter=${cityStr}`}`
    })
  })
});

export const { useGetCityOptionsQuery } = cityOptionsApi;
