import React from 'react';
import clsx from 'clsx';

import s from './index.module.scss';

export const PrimaryBtn = ({ customClassName, children, ...props }) => (
  <button type="button" className={clsx(s.main, customClassName)} {...props}>
    {children}
  </button>
);
