/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { Slices } from 'rtk/constants';

const initialState = {
  currentUser: null
};

export const userSlice = createSlice({
  name: Slices.user,
  initialState,
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload;
    }
  }
});

export const { setCurrentUser } = userSlice.actions;

export default userSlice.reducer;
