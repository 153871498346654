import React, { useEffect, useState } from 'react';
import { setCurrentUser } from 'rtk/slices/userSlice';
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import MainRouter from 'components/router';
import { apiUrl } from 'constants/data';
import {
  setBotInfoToken,
  setBotInfoCategories,
  setBotInfoTypePost,
  setBotInfoLink,
  setNavigatorLink,
  setLogoLink,
  setClientId,
  setMainDesc,
  setMiniDesc,
  setAdsChannelLink,
  setCommunityName,
  setAdCodes
} from 'rtk/slices/botInfoSlice';
import { defaultHeaders } from 'constants/headers';
import { convertTGLink } from 'utils/convert';

//  В корне приложения необходимо
// 1) из текущего url достать subdomen
// 2) получить clientId
// 2) получить инфо о боте и навигаторе (токен, категории и типы объявлений) + ссылку на лого

function App() {
  const dispatch = useDispatch();
  const clientId = useSelector((state) => state.botInfo.clientId);

  // получение clientId
  useEffect(() => {
    // для теста
    // const location = 'https://client.vitrinabot.online';
    // const location = 'https://avialarek.vitrinabot.online';
    // const location = 'https://peopleboats.vitrinabot.online';

    const location = window.location.href;
    const subdomen = location.split('.')[0].slice(8) || '';

    console.log('subdomen!!! ', subdomen);

    const getClientId = async () => {
      const response = await axios(`${apiUrl}client/${subdomen}`, {
        headers: defaultHeaders
      });

      if (response?.data?.id) {
        dispatch(setClientId(response?.data?.id));
      }
    };

    getClientId();
  }, []);

  // получение инфы о боте и навигаторе (токен, категории и типы объявлений) нативно бе RTK-query
  useEffect(() => {
    const getBotInfo = async () => {
      const response = await axios(`${apiUrl}bot/${clientId}`, {
        headers: defaultHeaders
      });
      const allBotInfo = response?.data;

      dispatch(setBotInfoToken(allBotInfo.token));
      dispatch(setBotInfoCategories(allBotInfo.categories));
      dispatch(setBotInfoTypePost(allBotInfo.typesPost));

      const botInfoLink = convertTGLink(allBotInfo.link);
      dispatch(setBotInfoLink(botInfoLink));
    };

    const getNavigatorInfo = async () => {
      const response = await axios(`${apiUrl}navigator/${clientId}`, {
        headers: defaultHeaders
      });

      const navigatorLink = convertTGLink(response?.data?.link);
      const adsChannelLink = convertTGLink(response?.data?.client?.channels?.[0]?.title);

      dispatch(setNavigatorLink(navigatorLink));
      dispatch(setAdsChannelLink(adsChannelLink));
      dispatch(setMainDesc(response?.data?.mainDesc));
      dispatch(setMiniDesc(response?.data?.miniDesc));
      dispatch(setCommunityName(response?.data?.client?.communityName));
    };

    const getWebInfo = async () => {
      const response = await axios(`${apiUrl}web/${clientId}`, {
        headers: defaultHeaders
      });

      dispatch(setLogoLink(response?.data?.logo));
    };

    const getAdCode = async () => {
      const response = await axios(`${apiUrl}code/${clientId}`, {
        headers: defaultHeaders
      });
      if (response?.data) dispatch(setAdCodes(response?.data));
    };

    if (clientId) {
      getBotInfo();
      getNavigatorInfo();
      getWebInfo();
      getAdCode();
    }
  }, [clientId]);

  const currentUser = JSON.parse(localStorage.getItem('marketCurrentUser'));
  if (currentUser) {
    dispatch(setCurrentUser(currentUser));
  }

  return <MainRouter />;
}

export default App;
