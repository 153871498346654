import React from 'react';
import { Main } from 'pages/main';
import { MainLayout } from 'components/layout/mainLayout';
import { SecondLayout } from 'components/layout/secondLayout';
import { AppPaths } from 'components/router/constants';
import { Trade } from 'pages/trade';
import { Detail } from 'pages/detail';
import { AboutProject } from 'pages/aboutProject';
import { AboutDeveloper } from 'pages/aboutDeveloper';
import {
  breadcrumbsMain,
  breadcrumbsDetail,
  breadcrumbsTrade,
  breadcrumbsAboutProject,
  breadcrumbsAboutDeveloper
} from 'constants/data';

export const routes = [
  {
    index: true,
    path: AppPaths.main,
    label: 'Главная',
    component: (route) => (
      <MainLayout route={route} breadcrumbs={breadcrumbsMain}>
        <Main />
      </MainLayout>
    ),
    routes: [
      {
        index: true,
        path: AppPaths.detail,
        label: 'Товар',
        component: (route) => (
          <SecondLayout route={route} breadcrumbs={breadcrumbsDetail}>
            <Detail />
          </SecondLayout>
        ),
        routes: []
      }
    ]
  },

  {
    index: true,
    path: AppPaths.trade,
    label: 'Сделка',
    component: (route) => (
      <SecondLayout route={route} breadcrumbs={breadcrumbsTrade}>
        <Trade />
      </SecondLayout>
    ),
    routes: []
  },
  {
    index: true,
    path: AppPaths.aboutProject,
    label: 'О проекте',
    component: (route) => (
      <MainLayout route={route} breadcrumbs={breadcrumbsAboutProject}>
        <AboutProject />
      </MainLayout>
    ),
    routes: []
  },

  {
    index: true,
    path: AppPaths.aboutDeveloper,
    label: 'О разработчике',
    component: (route) => (
      <MainLayout route={route} breadcrumbs={breadcrumbsAboutDeveloper}>
        <AboutDeveloper />
      </MainLayout>
    ),
    routes: []
  }
];
