import React from 'react';
import noPhoto from 'resources/images/noPhoto.png';
import { apiUrl } from 'constants/data';
import s from './index.module.scss';

export const Seller = ({ sellerAvatar, seller }) => (
  <div className={s.seller}>
    <div className={s.seller__avatar}>
      <img src={sellerAvatar ? `${apiUrl}${sellerAvatar}` : noPhoto} alt="avatar" />
    </div>
    <div className={s.seller__info}>
      <p className={s.seller__username}>{seller?.username}</p>
      <p className={s.seller__status}>Продавец</p>
    </div>
  </div>
);
