import React, { memo } from 'react';
import { Route, Routes } from 'react-router-dom';
import { routes } from 'components/router/routes';
import { generateAppRoutes } from 'components/router/utils';
import { useAuthMeQuery } from 'rtk/rtkApi/authApi';

const MainRouter = () => {
  const appRoutes = generateAppRoutes(routes);
  // const { data } = useAuthMeQuery();
  // console.log('data authMe ', data);

  return (
    <>
      <Routes>
        {appRoutes.map((route) => {
          const Component = () => route.component(route);
          return (
            <Route key={route.path} index={route.index} path={route.path} element={<Component />} />
          );
        })}
      </Routes>
    </>
  );
};

export default memo(MainRouter);
