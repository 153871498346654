import React, { useEffect } from 'react';
import { useParams } from 'react-router';
import { CommentList } from 'components/comments/commentList';
import { SimilarProductColumn } from 'components/similarProduct/similarProductColumn';
import { setMetaTags } from 'utils/setMetaTags';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { DetailContent } from 'components/containers/detailContent';
import { SimilarProductRow } from 'components/similarProduct/similarProductRow';
import { useSelector } from 'react-redux';
import { useGetPostQuery } from 'rtk/rtkApi/postApi';
import s from './index.module.scss';

export const Detail = () => {
  const { id } = useParams();
  const { data } = useGetPostQuery(id);
  const { isMobile } = useWindowDimensions();
  const communityName = useSelector((state) => state.botInfo.communityName);

  useEffect(() => {
    setMetaTags(
      `${data?.title}. ${communityName}. Vitrina bot.`,
      `${data?.title}. ${communityName}. Vitrina bot. Продукт для создания локального маркетплейса внутри тематических сообществ на базе telegram`
    );
  }, [data?.title, communityName]);

  return (
    <div className={s.wrapper}>
      <DetailContent id={id} />

      {/* секция с комментариями пока скрыта */}
      {/* <CommentList id={id} /> */}

      {isMobile ? <SimilarProductColumn id={id} /> : <SimilarProductRow id={id} />}
    </div>
  );
};
