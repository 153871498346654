import { useState, useEffect } from 'react';

export function useWindowDimensions() {
  function getWindowDimensions() {
    const windowWidth = window.innerWidth;

    return windowWidth;
  }

  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  function handleResize() {
    setWindowDimensions(getWindowDimensions());
  }

  const currentWindowDimensions = {
    isDesktop: windowDimensions >= 1200,
    isNotebook: windowDimensions >= 1024,
    isTablet: windowDimensions >= 768,
    isMobile: windowDimensions < 768
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return { ...currentWindowDimensions };
}
