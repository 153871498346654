/* eslint-disable */
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Card } from 'components/cards/card';
import { Filter } from 'components/form/custom/filter';
import { SortingPosts } from 'components/form/custom/sortingPosts';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { Pagination } from 'components/pagination';
import { Loader } from 'components/loader';
import { AttentionSVG, FilterSVG, GridSVG, ListSVG } from 'resources/svg';
import { setMetaTags } from 'utils/setMetaTags';
import { useGetPostsQuery } from 'rtk/rtkApi/postApi';
import { setCatalogViewType } from 'rtk/slices/filterSlice';
import { ModalSettings } from 'components/modal/modalSettings';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { convertToQueryParams } from 'utils/convert';
import { useModal } from 'hooks/useModal';
import { MainCardList } from 'components/cards/mainCardList';
import s from './index.module.scss';

export const Main = () => {
  const dispatch = useDispatch();
  const filters = useSelector((state) => state.filter);
  const catalogViewType = useSelector((state) => state.filter.catalogViewType);
  const currentPage = useSelector((state) => state.filter.page);
  const queryString = convertToQueryParams(filters);
  const botInfoToken = useSelector((state) => state.botInfo.botInfoToken);
  const communityName = useSelector((state) => state.botInfo.communityName);

  const { data, isLoading } = useGetPostsQuery({ filters: queryString, botInfoToken });

  const { isTablet } = useWindowDimensions();

  let cardList = [];
  let totalCards = 0;

  if (data) {
    cardList = data.list;
    totalCards = data?.meta?.total;
  }

  const { showModal, hideModal } = useModal();
  const showModalSettings = () => {
    showModal(ModalSettings, { showModal, hideModal });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  useEffect(() => {
    setMetaTags(
      `Объявления. ${communityName}. Vitrina bot`,
      `Vitrina bot. ${communityName}. Продукт для создания локального маркетплейса внутри тематических сообществ на базе telegram`
    );
  }, [communityName]);

  const handleSetCatalogViewType = (viewType) => {
    dispatch(setCatalogViewType(viewType));
  };

  return (
    <div className={s.wrapper}>
      <h1 className={s.catalog__title}>Объявления</h1>
      <div className={s.catalog__content}>
        <div className={s.catalog__filter__wrapper}>
          {isTablet && (
            <div className={s.catalog__filter}>
              <Filter />
            </div>
          )}
        </div>
        {isLoading ? (
          <div className={s.loader__wrapper}>
            <Loader />
          </div>
        ) : (
          <div className={s.cards}>
            <PrimaryBtn customClassName={s.cards__button__modal} onClick={showModalSettings}>
              <FilterSVG />
              <p>изменить фильтры</p>
            </PrimaryBtn>
            <div className={s.cards__heading}>
              <p className={s.cards__quantity}>
                {totalCards > 0
                  ? `Найдено ${totalCards} объявлений`
                  : 'Мы не нашли подходящих результатов'}
              </p>

              <div className={s.cards__heading__right}>
                <div className={s.view__control}>
                  <button onClick={() => handleSetCatalogViewType('column')}>
                    <ListSVG color={catalogViewType === 'column' ? '#231F20' : '#00000066'} />
                  </button>
                  <button onClick={() => handleSetCatalogViewType('tile')}>
                    <GridSVG color={catalogViewType === 'tile' ? '#231F20' : '#00000066'} />
                  </button>
                </div>
                <div className={s.cards__sorting}>
                  <SortingPosts />
                </div>
              </div>
            </div>
            <>
              <MainCardList cardList={cardList} />

              {totalCards > 0 && (
                <div className={s.pagination__wrapper}>
                  <Pagination totalCards={totalCards} />
                </div>
              )}
            </>
          </div>
        )}
      </div>
    </div>
  );
};
