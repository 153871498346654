import React, { useEffect, useMemo, useState } from 'react';
import { useGetSimilarPostsQuery } from 'rtk/rtkApi/similarPostApi';
import { convertToQueryParams } from 'utils/convert';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { useParams } from 'react-router';
import { Card } from 'components/cards/card';
import { ArrowRightSVG } from 'resources/svg';
import s from './index.module.scss';

export const SimilarProductColumn = () => {
  const [curPage, setCurPage] = useState(1);
  const { id } = useParams();

  const queryString = useMemo(
    () => convertToQueryParams({ quantity: 2, page: curPage }),
    [curPage]
  );

  const { data: similarData } = useGetSimilarPostsQuery({ id, queryString });
  const [similarPostsState, setSimilarPostState] = useState([]);

  const handleShowMore = () => {
    setCurPage((prev) => prev + 1);
  };

  useEffect(() => {
    if (similarData?.list) setSimilarPostState((prev) => [...prev, ...similarData?.list]);
  }, [similarData?.list]);

  useEffect(() => {
    setSimilarPostState([]);
    setCurPage(1);
    window.scrollTo(0, 0);
  }, [id]);

  if (!similarData?.meta?.total) {
    return null;
  }

  return (
    <div className={s.similar__wrapper}>
      <h4 className={s.similar__title}>Похожие товары</h4>
      <div className={s.similar__list}>
        {similarPostsState.length > 0 &&
          similarPostsState.map((card) => (
            <div className={s.similar__card__wrapper} key={card.id}>
              <Card cardData={card} />
            </div>
          ))}
      </div>
      {similarData?.meta?.total - similarPostsState.length > 0 && (
        <PrimaryBtn customClassName={s.similar__button__more} onClick={handleShowMore}>
          <p>Еще&nbsp;{similarData?.meta?.total - similarPostsState.length}&nbsp;похожих товаров</p>
          <ArrowRightSVG color="#1cc700" />
        </PrimaryBtn>
      )}
    </div>
  );
};
