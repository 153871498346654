import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { setMetaTags } from 'utils/setMetaTags';
import s from './index.module.scss';

export const AboutProject = () => {
  const mainDesc = useSelector((state) => state.botInfo.mainDesc);
  const miniDesc = useSelector((state) => state.botInfo.miniDesc);
  const communityName = useSelector((state) => state.botInfo.communityName);

  useEffect(() => {
    setMetaTags(
      `О проекте. ${communityName}. Vitrina bot`,
      `О проекте. ${communityName}. Vitrina bot. Продукт для создания локального маркетплейса внутри тематических сообществ на базе telegram `
    );
  }, [communityName]);

  return (
    <div className={s.wrapper}>
      <div className={s.inner}>
        <h1 className={s.title}>О проекте</h1>

        <h3 className={s.sub__title}>{miniDesc}</h3>

        <p className={s.desc}> {mainDesc}</p>
      </div>
    </div>
  );
};
