import * as Yup from 'yup';

const phoneRegExp = /^(\s*)?(\+)?([- _():=+]?\d[- _():=+]?){10,14}(\s*)?$/;

export const orderFormValidationSchema = Yup.object().shape({
  userName: Yup.string().required('обязательное поле *'),
  phone: Yup.string()
    .required('обязательное поле *')
    .min(16, 'невалидный номер')
    .matches(phoneRegExp, 'невалидный номер'),
  address: Yup.string().required('обязательное поле *')
});
