/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import clsx from 'clsx';
import { orderFormValidationSchema } from 'utils/validations';
import { FormContainer } from 'components/form/formContainer';
import { FormikTextArea } from 'components/form/inputs/textarea';
import { FormikPhone } from 'components/form/inputs/phone';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { FormikField } from 'components/form/inputs/input';
import s from './index.module.scss';

export const OrderForm = ({ sendOrder, totalCost, currencySign }) => {
  const initialValues = {
    userName: '',
    phone: '',
    address: '',
    comment: ''
  };

  const onSubmit = (values) => {
    sendOrder(values);
  };

  return (
    <>
      <FormContainer
        initialValues={initialValues}
        onSubmit={onSubmit}
        validateOnChange={false}
        validateOnBlur={false}
        validationSchema={orderFormValidationSchema}
      >
        {({ errors }) => (
          <>
            <div className={clsx(s.input__wrapper, errors?.userName && s.error)}>
              <label className={s.input__label} htmlFor="userName">
                Имя
              </label>
              <FormikField
                customClassName={s.input}
                type="text"
                name="userName"
                placeholder="Напишите имя"
              />
            </div>
            {errors?.userName && <p className={s.input__error__message}>{errors?.userName}</p>}

            <div className={clsx(s.input__wrapper, errors?.phone && s.error)}>
              <label className={s.input__label} htmlFor="userName">
                Телефон
              </label>
              <FormikPhone
                customClassName={s.input}
                name="phone"
                placeholder="Укажите номер телефона"
              />
            </div>
            {errors?.phone && <p className={s.input__error__message}>{errors?.phone}</p>}

            <div
              className={clsx(
                s.input__wrapper,
                s.input__wrapper__address,
                errors?.address && s.error
              )}
            >
              <label className={s.input__label} htmlFor="userName">
                Адрес
              </label>
              <FormikTextArea
                customClassName={clsx(s.input, s.input__address)}
                name="address"
                placeholder="Уточните ваш адрес "
              />
            </div>
            {errors?.address && <p className={s.input__error__message}>{errors?.address}</p>}

            <div className={clsx(s.input__wrapper, s.input__wrapper__comment)}>
              <label className={s.input__label} htmlFor="userName">
                Комментарий
              </label>
              <FormikTextArea
                customClassName={clsx(s.input, s.input__comment)}
                name="comment"
                placeholder="Оставьте комментарий к заказу"
              />
            </div>

            <p className={s.total__cost}>
              Общая стоимость:&nbsp;{totalCost.toLocaleString()}&nbsp;{currencySign}
            </p>

            <PrimaryBtn type="submit" customClassName={s.button__submit}>
              заказать
            </PrimaryBtn>
          </>
        )}
      </FormContainer>
    </>
  );
};
