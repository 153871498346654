import { FormContainer } from 'components/form/formContainer';
import { FormikNumber } from 'components/form/inputs/number';
import clsx from 'clsx';

import React from 'react';
import { getCurrencySign } from 'utils/convert';
import s from './index.module.scss';

export const PriceCounter = ({ productData, changeProducts }) => {
  const priceOfOne = productData?.price;
  const productId = productData?.id;
  const productCount = productData?.count;
  const allPrice = productData?.allPrice;
  const currencySign = getCurrencySign(productData?.currency?.ISO);

  const initialValues = {
    count: productCount
  };

  const handleChange = (value) => {
    const newValue = Number(value) && Number(value) > 0 ? value : 1;
    const newAllPrice = newValue * priceOfOne;
    changeProducts(productId, newValue, newAllPrice);
  };

  const handleIncrease = (formik) => {
    formik.setFieldValue('count', productCount + 1);
    changeProducts(productId, productCount + 1, allPrice + priceOfOne);
  };

  const handleDecrease = (formik) => {
    if (productCount > 1) {
      formik.setFieldValue('count', productCount - 1);
      changeProducts(productId, productCount - 1, allPrice - priceOfOne);
    }
  };

  const handleBlur = (e, formik) => {
    const curValue = e.target.value;

    if (!curValue || Number(curValue) < 1) {
      formik.setFieldValue('count', 1);
    }
  };

  return (
    <div className={s.counter__wrapper}>
      <div className={s.counter__form}>
        <FormContainer initialValues={initialValues} validateOnChange={false}>
          {(formik) => (
            <div className={s.counter__box}>
              <button
                className={clsx(s.counter__button, s.dec)}
                type="button"
                onClick={() => handleDecrease(formik)}
              >
                -
              </button>
              <div className={s.input__wrapper}>
                <FormikNumber
                  name="count"
                  min="1"
                  customClassName={s.input}
                  onChange={handleChange}
                  onBlur={(e) => handleBlur(e, formik)}
                />
              </div>
              <button
                className={clsx(s.counter__button, s.inc)}
                type="button"
                onClick={() => handleIncrease(formik)}
              >
                +
              </button>
            </div>
          )}
        </FormContainer>
      </div>
      <p className={s.counter__price}>
        {allPrice ? allPrice.toLocaleString() : 0} {currencySign}
      </p>
    </div>
  );
};
