import React from 'react';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { ModalBackdrop } from 'components/modal/modalBackdrop';
import { CloseSVG } from 'resources/svg';
import { Filter } from 'components/form/custom/filter';
import s from './index.module.scss';

export const ModalSettings = ({ hideModal }) => (
  <ModalBackdrop hideModal={hideModal}>
    <div className={s.container__scroll}>
      {/* eslint-disable-next-line */}
      <div className={s.container__flex} onClick={hideModal}>
        {/* eslint-disable-next-line */}
        <div className={s.content} onClick={(e) => e.stopPropagation()}>
          <PrimaryBtn customClassName={s.button__close} onClick={hideModal}>
            <CloseSVG />
            <p>закрыть</p>
          </PrimaryBtn>
          <Filter labelClassname={s.label__white} hideModal={hideModal} />
        </div>
      </div>
    </div>
  </ModalBackdrop>
);
