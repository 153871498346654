import React from 'react';
import { useFormikContext, useField } from 'formik';
import clsx from 'clsx';
import s from './index.module.scss';

export const FormikTextArea = (props) => {
  const { name = '', onChange, customClassName, ...other } = props;
  const [field] = useField(name);
  const form = useFormikContext();

  const handleChange = (e) => {
    form.setFieldValue(name, e.target.value);
    if (onChange) onChange(e);
  };

  const inputValue = field.value || '';

  const evalProps = {
    ...field,
    ...other,
    value: inputValue,
    id: name,
    onChange: handleChange
  };

  return (
    <>
      <textarea {...evalProps} className={clsx(s.input, customClassName)} />
    </>
  );
};
