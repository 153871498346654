import { configureStore } from '@reduxjs/toolkit';
import { postApi } from 'rtk/rtkApi/postApi';
import { similarPostApi } from 'rtk/rtkApi/similarPostApi';
import { commentApi } from 'rtk/rtkApi/commentApi';
import { sortOptionsApi } from 'rtk/rtkApi/sortOptionsApi';
import { cityOptionsApi } from 'rtk/rtkApi/cityOptionsApi';
import { authApi } from 'rtk/rtkApi/authApi';
import { tradeApi } from 'rtk/rtkApi/tradeApi';
import { userApi } from 'rtk/rtkApi/userApi';

import filterReducer from 'rtk/slices/filterSlice';
import userReducer from 'rtk/slices/userSlice';
import botInfoReducer from 'rtk/slices/botInfoSlice';

export const store = configureStore({
  reducer: {
    filter: filterReducer,
    user: userReducer,
    botInfo: botInfoReducer,
    [postApi.reducerPath]: postApi.reducer,
    [similarPostApi.reducerPath]: similarPostApi.reducer,
    [commentApi.reducerPath]: commentApi.reducer,
    [sortOptionsApi.reducerPath]: sortOptionsApi.reducer,
    [cityOptionsApi.reducerPath]: cityOptionsApi.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [tradeApi.reducerPath]: tradeApi.reducer,
    [userApi.reducerPath]: userApi.reducer
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(
      postApi.middleware,
      similarPostApi.middleware,
      commentApi.middleware,
      sortOptionsApi.middleware,
      cityOptionsApi.middleware,
      authApi.middleware,
      tradeApi.middleware,
      userApi.middleware
    )
});
