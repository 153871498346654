import React from 'react';
import { CheckSVG } from 'resources/svg';
import { useFormikContext, useField } from 'formik';
import clsx from 'clsx';
import s from './index.module.scss';

export const FormikCheckbox = (props) => {
  const { name = '', type = 'checkbox', onChange, value, customClassName, ...other } = props;
  const [field] = useField(name);
  const form = useFormikContext();
  const inputValue = field.value || false;

  const handleChange = (e) => {
    form.setFieldValue(name, e.target.checked);
    if (onChange) onChange(e);
  };

  const evalProps = {
    ...field,
    ...other,
    value,

    type
  };

  return (
    <>
      <label className={s.container} htmlFor={name}>
        <input
          className={s.checkbox__hidden}
          {...evalProps}
          checked={!!inputValue}
          onChange={handleChange}
          id={name}
          name={name}
          type="checkbox"
        />
        <span className={clsx(s.checkbox__styled, customClassName)}>
          <span className={s.svg}>
            <CheckSVG />
          </span>
        </span>
      </label>
    </>
  );
};
