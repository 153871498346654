import React, { useRef, useState } from 'react';
import { apiUrl } from 'constants/data';
import { LightboxGallery } from 'components/lightboxGallery';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import noPhoto from 'resources/images/noPhoto.png';
import clsx from 'clsx';
import { SliderArrowPrev, SliderArrowNext } from 'resources/svg';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import s from './index.module.scss';

export const DetailSlider = ({ images = [] }) => {
  const sliderRef = useRef(null);
  const imgTotal = images?.length;

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const imagesForGallery = images.map((item) => `${apiUrl}${item?.path}`);

  const openGallery = (ind) => {
    setPhotoIndex(ind);
    setIsOpen(true);
  };

  const calkSlidesToShow = () => {
    let slidesToShowDesktop = 1;
    let slidesToShowMobile = 1;

    if (imgTotal === 2) {
      slidesToShowDesktop = 2;
      slidesToShowMobile = 2;
    }

    if (imgTotal === 3) {
      slidesToShowDesktop = 3;
      slidesToShowMobile = 2;
    }

    if (imgTotal > 3) {
      slidesToShowDesktop = 3;
      slidesToShowMobile = 3;
    }

    return { slidesToShowDesktop, slidesToShowMobile };
  };

  const { slidesToShowDesktop, slidesToShowMobile } = calkSlidesToShow();

  const handleNext = () => {
    sliderRef.current.slickNext();
  };

  const handlePrev = () => {
    sliderRef.current.slickPrev();
  };
  const settings = {
    arrows: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShowDesktop,
    slidesToScroll: 1,
    variableWidth: true,
    draggable: false,

    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: slidesToShowMobile,
          draggable: true
        }
      }
    ]
  };

  if (imgTotal === 0) {
    return (
      <div className={s.slider__container}>
        <div className={s.no_photo__wrapper}>
          <img src={noPhoto} alt="img" />
        </div>
      </div>
    );
  }

  return (
    <div className={s.slider__container}>
      {isOpen && (
        <LightboxGallery
          images={imagesForGallery}
          setIsOpen={setIsOpen}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
        />
      )}
      <div className={clsx(s.slider)}>
        <Slider {...settings} ref={sliderRef}>
          {images.length > 0 &&
            images.map((img, ind) => (
              <button
                onClick={() => openGallery(ind)}
                type="button"
                key={img.id}
                className={s.slider__item}
              >
                <img src={`${apiUrl}${img?.path}`} alt="slider-img" />
              </button>
            ))}
        </Slider>
        <div className={s.slider__button__group}>
          {imgTotal > 3 && (
            <>
              <PrimaryBtn customClassName={s.slider__button} onClick={handlePrev}>
                <SliderArrowPrev />
              </PrimaryBtn>
              <PrimaryBtn customClassName={s.slider__button} onClick={handleNext}>
                <SliderArrowNext />
              </PrimaryBtn>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
