/* eslint-disable jsx-a11y/no-noninteractive-element-interactions
 */
/* eslint-disable jsx-a11y/click-events-have-key-events
 */
import parse from 'html-react-parser';
import { convertDate, getCurrencySign } from 'utils/convert';
import { useNavigate } from 'react-router';
import { useWindowDimensions } from 'hooks/useWindowDimensions';
import { PrimaryBtn } from 'components/buttons/primaryBtn';
import { ArrowRightSVG, LocationSVG } from 'resources/svg';
import { YandexShareBtn } from 'components/buttons/yandexShareBtn';
import { LightboxGallery } from 'components/lightboxGallery';
import { apiUrl } from 'constants/data';
import noPhoto from 'resources/images/noPhoto.png';
import React, { useMemo, useState } from 'react';
import s from './index.module.scss';

export const Card = ({ cardData }) => {
  const { id, category, createAt, title, city, price, description, photos, currency } = cardData;
  const currencySign = getCurrencySign(currency);
  const dateAd = convertDate(createAt);
  const navigate = useNavigate();
  const { isMobile } = useWindowDimensions();

  const photosForCard = useMemo(() => {
    if (isMobile) {
      return photos?.slice(0, 2);
    }
    return photos?.slice(0, 5);
  }, [isMobile, photos]);

  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const imagesForGallery = photos.map((item) => `${apiUrl}${item?.path}`);

  const openGallery = (ind) => {
    setPhotoIndex(ind);
    setIsOpen(true);
  };

  const handleNavigate = () => {
    navigate(`/good/${id}`);
  };

  return (
    <div className={s.card}>
      {isOpen && (
        <LightboxGallery
          images={imagesForGallery}
          setIsOpen={setIsOpen}
          photoIndex={photoIndex}
          setPhotoIndex={setPhotoIndex}
        />
      )}
      <p className={s.card__category}>{category}</p>
      <p className={s.card__date}>{dateAd}</p>
      <h3 className={s.card__title} onClick={handleNavigate}>
        {title}
      </h3>
      {city && (
        <div className={s.location}>
          <div className={s.location__icon}>
            <LocationSVG />
          </div>
          <p className={s.location__city}>{city}</p>
        </div>
      )}
      <div className={s.image__list}>
        {photosForCard && photosForCard.length > 0 ? (
          photosForCard.map((img, ind) => (
            <button
              onClick={() => openGallery(ind)}
              type="button"
              key={img.id}
              className={s.image__container}
            >
              <img className={s.image} src={`${apiUrl}${img?.path}`} alt="" />
            </button>
          ))
        ) : (
          <div className={s.image__container}>
            <img className={s.image} src={noPhoto} alt="" />
          </div>
        )}
      </div>
      <div className={s.price}>
        {price ? `${price.toLocaleString()} ${currencySign}` : 'Цена не указана'}
      </div>
      {description && !isMobile && (
        <p className={s.description}>{parse(description.replace(/\n/g, '<br/>'))}</p>
      )}
      <div className={s.button__group}>
        <PrimaryBtn customClassName={s.button__more} onClick={handleNavigate}>
          <p>подробнее</p>
          <ArrowRightSVG />
        </PrimaryBtn>
        <YandexShareBtn
          image={photos[0] && `${apiUrl}${photos[0].path}`}
          title={title}
          description={description}
        />
      </div>
    </div>
  );
};
